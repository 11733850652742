import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { postData } from "../../../Utils/Ajax";

function SavePassword({ steps, triggerNextStep }) {
  const [password, setPassword] = React.useState("");
  //const pwd = React.useRef();

  React.useEffect(() => {
    let patObj = JSON.parse(localStorage.getItem("patientObj"));
    console.log(patObj);

    if (patObj != null) {
      setPassword(patObj.password);
    }
  }, []);

  function handleSavePasswordOnClick() {
    //const url = "http://localhost:25782/Reactivity/PersistUser";
    const url = "https://apihelper.supportedpatient.com/Reactivity/PersistUser";

    let patObj = JSON.parse(localStorage.getItem("patientObj"));
    patObj.password = password;
    patObj.json = localStorage.getItem("ReactivityChat");
    localStorage.setItem("patientObj", JSON.stringify(patObj));

    console.log(patObj);

    // let data = {
    //   Email: patObj.email,
    //   Password: patObj.pwd,
    //   Json: patObj.json,
    // };

    if (password.length > 0) {
      postData(url, patObj).then((data) => {
        console.log(data); // JSON data parsed by `data.json()` call

        if (data.result == "success" || data.result == "alreadyAdded") {
          triggerNextStep({ trigger: "20" });
        } else {
          alert("an error has occured, please contact the admin!");
        }
      });
    } else {
      alert("Please Type a Password");
    }
  }

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const logIn = (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          textAlign: "center",
          height: 140,
        }}
      >
        <table
          style={{
            backgroundColor: "ivory",
            textAlign: "center",
          }}
        >
          <tbody>
            <tr>
              <td>Select a password</td>
            </tr>
            <tr>
              <td>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="filled"
                  type={"password"}
                  onChange={handleChange}
                  value={password}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Chip
                  label="Send"
                  color="primary"
                  onClick={handleSavePasswordOnClick}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  return logIn;
}

export default SavePassword;

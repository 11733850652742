export const json = {
  title: "Reiss PAS",
  logoPosition: "right",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "radiogroup",
          name: "Q1",
          title: "Do you have adequate access to food, shelter and safety?",
          isRequired: true,
          choices: [
            {
              value: "20,Never",
              text: "Never",
            },
            {
              value: "15,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "5,Frequently",
              text: "Frequently",
            },
            {
              value: "1,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "radiogroup",
          name: "Q2",
          title:
            "Do you use prescription pain medications to manage pain including narcotics or opiates (eg. Percocet, Vicodin, Hydrocodone, Dilaudid, OxyContin, Tramadol, Tylenol with Codeine etc)",
          isRequired: true,
          choices: [
            {
              value: "1,Never",
              text: "Never",
            },
            {
              value: "5,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "15,Frequently",
              text: "Frequently",
            },
            {
              value: "20,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
    {
      name: "page3",
      elements: [
        {
          type: "radiogroup",
          name: "Q3",
          title: "Do you use marijuana to manage pain?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page4",
      elements: [
        {
          type: "radiogroup",
          name: "Q4",
          title:
            "Do you use street drugs such as cocaine or heroin to manage pain?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page5",
      elements: [
        {
          type: "radiogroup",
          name: "Q5",
          title:
            "On any single occasion during the past three months, have you had more than five drinks containing alcohol?",
          isRequired: true,
          choices: [
            {
              value: "10,Yes",
              text: "Yes",
            },
            {
              value: "1,No",
              text: "No",
            },
          ],
        },
      ],
    },
    {
      name: "page6",
      elements: [
        {
          type: "radiogroup",
          name: "Q6",
          title: "Do you have thoughts of suicide?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page7",
      elements: [
        {
          type: "radiogroup",
          name: "Q7",
          title: "Do you smoke?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page8",
      elements: [
        {
          type: "radiogroup",
          name: "Q8",
          title:
            "Do you worry or feel anxious about the long-term impact of your medical treatment and illness?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page9",
      elements: [
        {
          type: "radiogroup",
          name: "Q9",
          title:
            "Do you feel distressed when you think about your disease and how it impacts your life?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page10",
      elements: [
        {
          type: "radiogroup",
          name: "Q10",
          title:
            "Do you delay seeking medical care when you need it because of previous traumatic experiences?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page11",
      elements: [
        {
          type: "radiogroup",
          name: "Q11",
          title:
            "Do you have practical support for activities of daily living (eg. food shopping, errands, transportation to medical appointments etc)?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page12",
      elements: [
        {
          type: "radiogroup",
          name: "Q12",
          title:
            "Do you feel alone or as though you don't have people you can talk to about your illness?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page13",
      elements: [
        {
          type: "radiogroup",
          name: "Q13",
          title:
            "Do you have agreement from your family and friends about the medical treatment that your doctor is recommending?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page14",
      elements: [
        {
          type: "radiogroup",
          name: "Q14",
          title:
            "Does your disease prevent you from developing meaningful relationships?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page15",
      elements: [
        {
          type: "radiogroup",
          name: "Q15",
          title: "Do you work outside the house?",
          isRequired: true,
          choices: [
            {
              value: "1,Yes",
              text: "Yes",
            },
            {
              value: "0,No",
              text: "No",
            },
          ],
        },
      ],
    },
    {
      name: "page16",
      elements: [
        {
          type: "radiogroup",
          name: "Q16",
          title:
            "Do you not work outside the home because of your illness or illness related issues?",
          isRequired: true,
          choices: [
            {
              value: "10,Yes",
              text: "Yes",
            },
            {
              value: "1,No",
              text: "No",
            },
          ],
        },
      ],
    },
    {
      name: "page17",
      elements: [
        {
          type: "radiogroup",
          name: "Q17",
          title: "Do you go to school?",
          isRequired: true,
          choices: [
            {
              value: "1,Yes",
              text: "Yes",
            },
            {
              value: "0,No",
              text: "No",
            },
          ],
        },
      ],
    },
    {
      name: "page18",
      elements: [
        {
          type: "radiogroup",
          name: "Q18",
          title:
            "Can you obtain the medical care you need with your current health insurance?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page19",
      elements: [
        {
          type: "radiogroup",
          name: "Q19",
          title: "How frequently do you need to change your health insurance?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page20",
      elements: [
        {
          type: "radiogroup",
          name: "Q20",
          title:
            "Can you afford your medical care and treatments that your doctor recommends?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page21",
      elements: [
        {
          type: "radiogroup",
          name: "Q21",
          title:
            "Do you have religious or cultural beliefs that are difficult to discuss with your doctor or for your healthcare team to understand?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page22",
      elements: [
        {
          type: "radiogroup",
          name: "Q22",
          title:
            "Do you have religious or cultural beliefs that prevent you from following the treatment your doctor recommends?",
          choicesFromQuestion: "Q2",
          choices: ["Item 1", "Item 2", "Item 3"],
        },
      ],
    },
    {
      name: "page23",
      elements: [
        {
          type: "radiogroup",
          name: "Q23",
          title: "Can you trust your doctor  to do what is in your interests?",
          isRequired: true,
          choicesFromQuestion: "Q2",
        },
      ],
    },
    {
      name: "page24",
      elements: [
        {
          type: "radiogroup",
          name: "Q24",
          title:
            "Do you have sufficient access and communication with your medical team that enables your needs to be met?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page25",
      elements: [
        {
          type: "radiogroup",
          name: "Q25",
          title:
            "How often have your disease symptoms been controlled over the past year?",
          isRequired: true,
          choicesFromQuestion: "Q1",
        },
      ],
    },
    {
      name: "page26",
      elements: [
        {
          type: "radiogroup",
          name: "Q26",
          title:
            "Do you have more than one chronic illness (including mental illness) that requires ongoing treatment?",
          isRequired: true,
          choices: [
            {
              value: "10,Yes",
              text: "Yes",
            },
            {
              value: "1,No",
              text: "No",
            },
          ],
        },
      ],
    },
    {
      name: "page27",
      elements: [
        {
          type: "radiogroup",
          name: "Q27",
          title: "Do you worry about maintaining your current job?",
          isRequired: true,
          choices: [
            {
              value: "1,Never",
              text: "Never",
            },
            {
              value: "5,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "15,Frequently",
              text: "Frequently",
            },
            {
              value: "20,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
    {
      name: "page28",
      elements: [
        {
          type: "radiogroup",
          name: "Q28",
          title: "Do you worry about getting promotions?",
          isRequired: true,
          choices: [
            {
              value: "1,Never",
              text: "Never",
            },
            {
              value: "5,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "15,Frequently",
              text: "Frequently",
            },
            {
              value: "20,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
    {
      name: "page29",
      elements: [
        {
          type: "radiogroup",
          name: "Q29",
          title:
            "Do you have the ability to obtain accommodations for your illness related needs at your workplace?",
          isRequired: true,
          choices: [
            {
              value: "20,Never",
              text: "Never",
            },
            {
              value: "15,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "5,Frequently",
              text: "Frequently",
            },
            {
              value: "1,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
    {
      name: "page30",
      elements: [
        {
          type: "radiogroup",
          name: "Q30",
          title:
            "Are you at a disadvantage in your job due to your illness or related issues?",
          isRequired: true,
          choices: [
            {
              value: "1,Never",
              text: "Never",
            },
            {
              value: "5,Rarely",
              text: "Rarely",
            },
            {
              value: "10,Sometimes",
              text: "Sometimes",
            },
            {
              value: "15,Frequently",
              text: "Frequently",
            },
            {
              value: "20,Always",
              text: "Always",
            },
          ],
        },
      ],
    },
  ],
  showProgressBar: "top",
  progressBarType: "questions",
  widthMode: "responsive",
};

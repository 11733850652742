import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProductHeroLayout from "./modules/views/ProductHeroLayoutChatBot";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import BContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "bootstrap";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});
const backgroundImage =
  "http://ibdsfcertified.com/wp-content/themes/IBDSFCertified/images/Therapy.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const testimonials = [
  {
    id: "1",
    image:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/80%20years%20old%20woman.jpg",
    patient: "AR",
    subHeaderText: "80 years old, diagnosis of gastroparesis",
    contentText:
      "There really are not enough superlatives to compliment the amazing journey you provided me to discover a more authentic life despite GI disease!",
    subContentText: [],
  },
  {
    id: "2",
    image:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Man%20with%20gym%20bag.jpg",
    patient: "KY",
    subHeaderText: "30 years old, diagnosis of IBS",
    contentText:
      "Marci has had an incredible effect on my life. After just a year of working with her, I noticed that my reactivity was way down and my medically unexplained GI issues no longer had the power to break my spirit. I hardly recognized myself (in the best way possible).",
    subContentText: [
      "I was willing to push through what can be difficult psychological excavation to better my mental health. Having Marci as a guide made every minute of that discomfort worth it! Today, I feel 100 pounds lighter- unshackled by the anxiety, volatility, victimhood and irritability that used to embitter me and aggravate my GI symptoms. I can be in a room with my mom without feeling triggered; I’ve spoken to and set boundaries with my father who I did not talk to for over 8 months; I find joy and excitement in my career which used to fill me with dread; I have a trusty tool belt I can utilize in stressful scenarios… the list goes on! All of the above would not be possible without the meticulous teachings that Marci has so carefully created.",
    ],
  },
  {
    id: "3",
    image:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Young%20professional%20therapist%20copy.jpeg",
    patient: "LR",
    subHeaderText:
      "26 year old, therapist and patient with a diagnosis of Crohn’s Disease",
    contentText:
      "Dr. Reiss’ teachings on reactivity have changed how I work with my patients.",
    subContentText: [
      "Helping them discover their unique symptoms and process has given them a much greater ability to understand and control their anxiety. We are able to go so much deeper in our work together thanks to this new level of discovery. Even more, Dr. Reiss’ framework has help me better understand my own reactivity which has led to tangible results in my own wellness goals. My patients’ and my ability to recover from anxious episodes quickly is remarkable.",
    ],
  },
  {
    id: "4",
    image:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/45%20year%20old%20woman.jpg",
    patient: "DK",
    subHeaderText: "45 year old, diagnosis of anxiety",
    contentText:
      "I’m beyond happy to have found Dr. Reiss. She came into my life in a major inflection point of growth and change and her tools and resources have helped me tremendously.",
    subContentText: [
      " I loved our challenging conversations and continue to use the skills when needed.  I still enjoy touching base when I feel like I need a good grounding again. She will be forever in my phone. :).",
    ],
  },
];

export default function TestimonialsCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div>
        <div
          className="centered-div"
          style={{
            // backgroundImage: `url("https://reactivity.s3.us-west-1.amazonaws.com/chatbot/dreamstime_s_natureBanner1.jpg")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "150px",
            width: "100%",
            textShadow: "6px 6px 3px gray",
          }}
        >
          {/* <img
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/dreamstime_xs_testimonials1.jpg"
              width={"300"}
              disableGutters={true}
            ></img> */}
          <Typography variant="h2" color="white">
            TESTIMONIALS
          </Typography>
        </div>

        <Container>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {testimonials.map((testimonial) => (
              <li key={testimonial.id}>
                <br />
                <BContainer>
                  <Card
                    sx={{
                      maxWidth: "100%",
                    }}
                  >
                    <Row style={{ display: "flex" }}>
                      <Col sm={4} style={{ flex: 1 }}>
                        <CardMedia
                          component="img"
                          height="300"
                          image={testimonial.image}
                        />
                      </Col>
                      <Col sm={4} style={{ flex: 2 }}>
                        <CardHeader
                          avatar={
                            <Avatar
                              sx={{ bgcolor: red[500] }}
                              aria-label="recipe"
                            ></Avatar>
                          }
                          title={testimonial.patient}
                          subheader={testimonial.subHeaderText}
                        />
                        <CardContent>
                          <Typography variant="body1" color="text.secondary">
                            {testimonial.contentText}
                          </Typography>
                          {testimonial.subContentText.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography variant="body2"></Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul
                                  style={{ listStyle: "none", paddingLeft: 0 }}
                                >
                                  {testimonial.subContentText.map(
                                    (subCText, index) => (
                                      <li key={index}>
                                        <Typography>{subCText}</Typography>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </CardContent>
                      </Col>
                    </Row>
                  </Card>
                </BContainer>
              </li>
            ))}
          </ul>
        </Container>

        <Background
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundColor: "#7fc7d9", // Average color of the background image.
            backgroundPosition: "center",
            position: "fixed",
          }}
        />
      </div>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "common.black",
          opacity: 0.5,
          zIndex: -1,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </>
  );
}

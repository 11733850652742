import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import SentimentSatisfiedAltTwoToneIcon from "@mui/icons-material/SentimentSatisfiedAltTwoTone";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import { Margin } from "@mui/icons-material";

export default function VerticalLinearStepper({
  speechText,
  videoId,
  videoUrlA = "",
  videoUrlB = "",
  videoUrlC = "",
  nextStep,
  steps,
  triggerNextStep,
}) {
  const selectAvatar = () => {
    switch (localStorage.getItem("avatarType")) {
      default:
        return videoUrlA;
        break;
      case "Black":
        return videoUrlB;
        break;
      case "Caucasian":
        return videoUrlC;
        break;
    }
  };

  const selectAvatarChip = () => {
    switch (localStorage.getItem("avatarType")) {
      default:
        return "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Anna_Avatar.jpg";
        break;
      case "Black":
        return "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Sally_Avatar.jpg";
        break;
      case "Caucasian":
        return "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Aiden_Avatar.jpg";
        break;
    }
  };

  const videoRef = React.useRef();

  const [activeStep, setActiveStep] = React.useState(() => {
    if (localStorage.getItem("watchedVideos")) {
      const watchedVideos = JSON.parse(localStorage.getItem("watchedVideos"));
      const found = watchedVideos.find((element) => element === videoId);
      if (found) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });

  const [buttonName, setButtonName] = React.useState("");
  const [videoEnd, setVideoEnd] = React.useState(false);
  const [stop, setStop] = React.useState(false);

  const handleVideo = () => {
    setStop(!stop);
    if (stop === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setVideoEnd(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onEndedCallBack = () => {
    triggerNextStep({
      trigger: nextStep,
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (!localStorage.getItem("watchedVideos")) {
      const watchedVideos = [];
      watchedVideos.push(videoId);
      localStorage.setItem("watchedVideos", JSON.stringify(watchedVideos));
    } else {
      const watchedVideos = JSON.parse(localStorage.getItem("watchedVideos"));
      watchedVideos.push(videoId);
      localStorage.setItem("watchedVideos", JSON.stringify(watchedVideos));
    }
  };

  const onPlayCallBack = () => {
    triggerNextStep({
      trigger: nextStep,
    });
  };

  React.useEffect(() => {}, []);

  const pasos = [
    {
      label: "",
      description: (
        <video
          id="MyVideo"
          width={"100%"}
          controls={true}
          controlsList={"nodownload noremoteplayback"}
          onContextMenu={(e) => e.preventDefault()}
          autoPlay={true}
          onEnded={() => onEndedCallBack()}
          // onPlay={() => onPlayCallBack()}
          ref={videoRef}
          onClick={handleVideo}
        >
          <source src={selectAvatar()} type="video/mp4" />
        </video>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ maxWidth: 400, paddingTop: 4, }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {pasos.map((step, index) => (
            <Step key={step.label}>
              {/* <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel> */}
              <StepContent>
                <Box sx={{ ml: -0.35 }}>
                  <Typography>{step.description}</Typography>
                </Box>
                <Box sx={{ mb: 2, display: "none" }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {/* {index === pasos.length - 1 ? "Finish" : buttonName} */}
                      {buttonName}
                    </Button>
                    {/* <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button> */}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === pasos.length && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignText: "center",
              textAlign: "center",
              marginBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
              alignContent: "center",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={selectAvatarChip()}
              sx={{ width: 50, height: 50, m: "auto", display: "block" }}
            />

            <h5>{speechText} </h5>
          </div>
        )}
      </Box>
    </>
  );
}

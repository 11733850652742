import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  //position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "80vh",
  },
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});
const backgroundImage =
  "http://ibdsfcertified.com/wp-content/themes/IBDSFCertified/images/Therapy.jpg";

function ProductHeroLayout(props) {
  const { sxBackground, children } = props;
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <ProductHeroLayoutRoot>
      {isDesktopOrLaptop && (
        <Container
          sx={{
            mt: 30,
            mb: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "common.black",
              opacity: 0.3,
              zIndex: -1,
            }}
          />
          <Background
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundColor: "#7fc7d9", // Average color of the background image.
              backgroundPosition: "center",
            }}
          />
        </Container>
      )}
      {isTabletOrMobile && (
        <Container
          sx={{
            mt: 0,
            mb: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "common.black",
              opacity: 0.3,
              zIndex: -1,
            }}
          />
          <Background
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundColor: "#7fc7d9", // Average color of the background image.
              backgroundPosition: "center",
            }}
          />
        </Container>
      )}
    </ProductHeroLayoutRoot>
  );
}

ProductHeroLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ProductHeroLayout;

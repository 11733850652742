import * as React from "react";
import Box from "@mui/material/Box";
import withRoot from "../withRoot";
import Container from "./Container";
import Bot from "./Bot";
import Contenedor from "@mui/material/Container";
import ProductHeroLayout from "../views/ProductHeroLayoutChatBot";
import { styled } from "@mui/material/styles";
import ProductValues from "../views/ProductValues";
import HorizontalStepper from "../components/HorizontalStepper";
import { useMediaQuery } from "react-responsive";
import { LocalActivity } from "@mui/icons-material";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.black,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "80vh",
  },
}));

export const StepContext = React.createContext();

function StepProvider(props) {
  const [steps, setSteps] = React.useState(0);
  const value = [steps, setSteps];
  return <StepContext.Provider value={value} {...props} />;
}

function Decider() {
  const [steps] = React.useContext(StepContext);
  const stepper = <HorizontalStepper />;
  const bot = (
    <Bot>
      {" "}
      <Container />
    </Bot>
  );

  //alert(steps);
  const horizontalStep = localStorage.getItem("horizontalStep");

  if (horizontalStep == null || horizontalStep === "0") {
    return stepper;
  }
  if (horizontalStep === "1") {
    return bot;
  }
  console.log(horizontalStep);
  if (horizontalStep == 0) {
    return stepper;
  }
  if (horizontalStep == 1) {
    return bot;
  }
}

function ChatBotView() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <ProductHeroLayout>
          <div className="smartphone">
            <div className="content">
              <StepProvider>
                <Decider />
              </StepProvider>
            </div>
          </div>
        </ProductHeroLayout>
      )}

      {isTabletOrMobile && (
        <div className="content">
          <StepProvider>
            <Decider />
          </StepProvider>
        </div>
      )}

      {/* <ProductValues /> */}
    </>
  );
}

export default withRoot(ChatBotView);

import React, { useContext } from "react";
import ChatBot from "react-simple-chatbot";
import { chatBotContext } from "./Bot";
import { useMediaQuery } from "react-responsive";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Container = () => {
  const steps = useContext(chatBotContext);
  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      {isDesktopOrLaptop && (
        <div
          className="Container"
          style={{ height: "550px", position: "relative" }}
        >
          <ChatBot
            style={{ position: "absolute" }}
            headerTitle="Reactivity"
            speechSynthesis={{ enable: false, lang: "en" }}
            steps={steps}
            cache={true}
            cacheName={"ReactivityChat"}
            enableMobileAutoFocus={true}
            botDelay={1500}
            width="100%"
            height="100%"
            hideHeader={true}
            hideSubmitButton={false}
            bubbleOptionStyle={{ backgroundColor: "#9E6260", color: "white" }}
            // bubbleStyle={{ backgroundColor: "#EBA8A6", color: "black" }}
            botAvatar="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Atom_1.0_icon.png"
          />
        </div>
      )}

      {isTabletOrMobile && (
        <div
          className="Container"
          style={{ height: windowSize.innerHeight, position: "relative" }}
        >
          <ChatBot
            style={{ position: "absolute" }}
            headerTitle="Reactivity"
            speechSynthesis={{ enable: false, lang: "en" }}
            steps={steps}
            cache={true}
            cacheName={"ReactivityChat"}
            enableMobileAutoFocus={true}
            botDelay={1500}
            width="100%"
            height="100%"
            hideHeader={true}
            bubbleOptionStyle={{ backgroundColor: "#9E6260", color: "white" }}
            // bubbleStyle={{ backgroundColor: "#EBA8A6", color: "black" }}
            botAvatar="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Atom_1.0_icon.png"
          />
        </div>
      )}
    </>
  );
};

export default Container;

import * as React from "react";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    info: {
      main: "#9F615F",
      darker: "#053e85",
    },
    warning: {
      main: "#E8EABE",
      contrastText: "#000000",
    },
  },
});

function SelectToggleDiseases() {
  const [diseaseColors, setDiseaseColors] = React.useState(
    () =>
      JSON.parse(window.localStorage.getItem("diseaseColors")) ?? {
        A: "info",
        B: "info",
        C: "info",
        D: "info",
        E: "info",
        F: "info",
        G: "info",
        H: "info",
        I: "info",
        J: "info",
        K: "info",
        L: "info",
      }
  );

  React.useEffect(() => {
    localStorage.setItem("diseaseColors", JSON.stringify(diseaseColors));
  }, [diseaseColors]);

  function toogleColor(index) {
    switch (index) {
      case "A":
        if (diseaseColors.A === "info") {
          setDiseaseColors({ ...diseaseColors, A: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, A: "info" });
        }
        break;
      case "B":
        if (diseaseColors.B === "info") {
          setDiseaseColors({ ...diseaseColors, B: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, B: "info" });
        }
        break;
      case "C":
        if (diseaseColors.C === "info") {
          setDiseaseColors({ ...diseaseColors, C: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, C: "info" });
        }
        break;
      case "D":
        if (diseaseColors.D === "info") {
          setDiseaseColors({ ...diseaseColors, D: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, D: "info" });
        }
        break;
      case "E":
        if (diseaseColors.E === "info") {
          setDiseaseColors({ ...diseaseColors, E: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, E: "info" });
        }
        break;
      case "F":
        if (diseaseColors.F === "info") {
          setDiseaseColors({ ...diseaseColors, F: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, F: "info" });
        }
        break;
      case "G":
        if (diseaseColors.G === "info") {
          setDiseaseColors({ ...diseaseColors, G: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, G: "info" });
        }
        break;
      case "H":
        if (diseaseColors.H === "info") {
          setDiseaseColors({ ...diseaseColors, H: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, H: "info" });
        }
        break;
      case "I":
        if (diseaseColors.I === "info") {
          setDiseaseColors({ ...diseaseColors, I: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, I: "info" });
        }
        break;
      case "J":
        if (diseaseColors.J === "info") {
          setDiseaseColors({ ...diseaseColors, J: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, J: "info" });
        }
        break;
      case "K":
        if (diseaseColors.K === "info") {
          setDiseaseColors({ ...diseaseColors, K: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, K: "info" });
        }
        break;
      case "L":
        if (diseaseColors.L === "info") {
          setDiseaseColors({ ...diseaseColors, L: "warning" });
        } else {
          setDiseaseColors({ ...diseaseColors, L: "info" });
        }
        break;
    }
  }

  const buttons = (
    <>
      <div
        style={{
          textAlign: "center",
          textShadow: "3px 3px 3px #90c1f0",
        }}
      >
        <ThemeProvider theme={theme}>
          What do you want to control?
          <br />
          <br />
          Select as many as you wish.
          <br />
          <br />
          <Chip
            label="Gastroparesis"
            onClick={() => toogleColor("A")}
            color={diseaseColors.A}
          />
          <br />
          <Chip
            label="Crohn’s Disease"
            onClick={() => toogleColor("B")}
            color={diseaseColors.B}
          />
          <br />
          <Chip
            label="Ulcerative Colitis"
            onClick={() => toogleColor("C")}
            color={diseaseColors.C}
          />
          <br />
          <Chip
            label="Irritable Bowel Syndrome"
            onClick={() => toogleColor("D")}
            color={diseaseColors.D}
          />
          <br />
          <Chip
            label="Celiac"
            onClick={() => toogleColor("E")}
            color={diseaseColors.E}
          />
          <br />
          <Chip
            label="Pain"
            onClick={() => toogleColor("F")}
            color={diseaseColors.F}
          />
          <br />
          <Chip
            label="Migraine"
            onClick={() => toogleColor("G")}
            color={diseaseColors.G}
          />
          <br />
          <Chip
            label="Joint Inflammation"
            onClick={() => toogleColor("H")}
            color={diseaseColors.H}
          />
          <br />
          <Chip
            label="Psoriasis"
            onClick={() => toogleColor("I")}
            color={diseaseColors.I}
          />
          <br />
          <Chip
            label="Misophonia"
            onClick={() => toogleColor("J")}
            color={diseaseColors.J}
          />
          <br />
          <Chip
            label="PTSD"
            onClick={() => toogleColor("K")}
            color={diseaseColors.K}
          />
          <br />
          <Chip
            label="Sjogren Syndrome"
            onClick={() => toogleColor("L")}
            color={diseaseColors.L}
          />
        </ThemeProvider>
      </div>
    </>
  );

  return buttons;
}

export default SelectToggleDiseases;

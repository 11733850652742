import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});
const backgroundImage =
  "http://ibdsfcertified.com/wp-content/themes/IBDSFCertified/images/Therapy.jpg";

export default function MyStory() {
  return (
    <>
      <div>
        <div
          className="centered-div"
          style={{
            // backgroundImage: `url("https://reactivity.s3.us-west-1.amazonaws.com/chatbot/dreamstime_s_natureBanner1.jpg")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "150px",
            width: "100%",
          }}
        >
          {/* <img
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/dreamstime_xs_testimonials1.jpg"
              width={"300"}
              disableGutters={true}
            ></img> */}
          <Typography variant="h3" gutterBottom color="white">
            My Story
          </Typography>
        </div>
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom color="white">
            I was in my mid-20’s with my world ahead of me…
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            I was on my way to medical school and newly married. My husband and
            I were excited about spending an upcoming Valentine’s Day weekend
            together.
            <br />
            <br />
            That weekend I had a raging headache, but I was able to control it
            with some over-the-counter medication. Then, on Sunday afternoon, a
            searing pain went through my stomach while I was walking along the
            beach. Doubled over in pain, my husband helped me back to our hotel
            where I began to hemorrhage from my bottom. I spent hours crouched
            in pain in the bathroom, losing blood and getting weaker.
            <br />
            <br />I spent hours crouched in pain in the bathroom, losing blood
            and getting weaker.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            Scared and in shock, we did nothing.
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            I was embarrassed and confused when the bleeding didn’t slow down.
            How was I supposed to get out of the bathroom?
            <br />
            <br />
            Twelve hours later, when I was so weak I could only crawl out of the
            bathroom, I called my brother, a doctor. It took his vast medical
            training to yell, “What are you calling me for? Get to a hospital!”
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            The next 72 hours flew by in a whirlwind of doctors and tests and
            procedures.
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            I was hospitalized and then given the diagnosis of Crohn’s disease.
            I will never forget the moment the doctor gave me the news. He
            simply stated those life-altering words, “You have Crohn’s disease,”
            and then he walked out of the room.
            <br />
            <br />I left the hospital with a Hep-lock, a tube in my arm to
            self-inject medication, and an incurable disease.
            <br />
            <br />I felt lost and confused, as though I was in a haze and I had
            no way to see clearly. I recall driving home with my husband
            wondering what had just happened to my life.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            For the next 14 years, I lived the life of a very sick patient.
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            I was hospitalized 162 times and survived sepsis twice. Every facet
            of my world was impacted by my illness: my family, my marriage, my
            work, and my children.
            <br />
            <br />I never made it to medical school, but I was able to devote my
            life to helping chronic disease patients manage their lives
            successfully; find clarity in the chaos; and use adversity as a
            catalyst for growth, development and happiness.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            You’ll never believe the end of the story…
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            It’s unbelievable, but my illness over the 14-year period was caused
            by taking numerous medications for the disease I didn’t have! The
            initial bleed that led to my diagnosis of Crohn’s disease was
            determined to be an adverse reaction to the medication I took for my
            headache!
            <br />
            <br />I was pulled off all medication, and I had a full recovery.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            I lost 14 years of my life to a disease I didn’t have.
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            Human error altered my life in unimaginable ways. I couldn’t pursue
            the career I had always wanted. I couldn’t be the mom and wife that
            I had wanted to be. I was always battling fatigue, and worry, never
            knowing when I would end up back in the hospital, or stuck in bed,
            too sick or weak to get up.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            Choosing to devote my life to those still suffering…
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            With my health and energy restored, I have devoted my life to those
            still suffering. I was released from the prison of chronic illness,
            but I learned the pain of that prison.
            <br />
            <br />I used to say that only those “who have it” get it. Only those
            who have the burden and pain of chronic illness can understand how
            pervasive it is – how it impacts every choice, every decision, every
            relationship. I bring the unique insights I learned through my
            experiences to my work with each patient.
            <br />
            <br />I know what it takes to lessen the pain and live successfully.
            It takes becoming comfortable never knowing when illness will flare
            and change your plans – from school plans to vacation plans to daily
            activity plans.
            <br />
            <br />
            It takes building an identity outside of your illness, even though
            illness is the lens through which you live each day. It takes seeing
            the other parts of you – your strengths, your gifts, your
            uniqueness, all of which you forget as illness becomes the lens
            through which you see yourself.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            Call me Today
          </Typography>
          <Typography variant="body1" gutterBottom color="white">
            Let me help you remember that you are not your illness. You are so
            much more than that which holds you back. Take back your confidence
            or build it from scratch.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" gutterBottom color="white">
            The world doesn’t have to be limited. Your body may be limited, but
            YOU are not. Call me: (310) 552-2033.
          </Typography>
        </Container>
        <Background
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundColor: "#7fc7d9", // Average color of the background image.
            backgroundPosition: "center",
            position: "fixed",
          }}
        />
      </div>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "common.black",
          opacity: 0.6,
          zIndex: -1,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </>
  );
}

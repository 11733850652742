import React from "react";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "./SurveyQuestions";

StylesManager.applyTheme("defaultV2");

const ReissCategoryPrompt = {
  Family: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "Social support is very helpful for successful adaptation to illness. However, you have indicated that you do not have enough support from your family and/or your friends.",
      "We understand that illness can be isolating and your doctor and healthcare team would like to try to help.  We understand that when you disclose your illness condition to others, people react in different ways, and not all of those ways are helpful or supportive. It is challenging to manage so many changing social dynamics and there is very little if any education on how to adjust to and navigate the changing relationship dynamics.",
      "It is important to make sure that you have enough support, which can help with feelings of anxiety, depression and social isolation.",
      "There are some high quality, facilitated online patient and disease specific communities. It is worth seeking out support from others who understand your illness experience. Ask your doctor or healthcare team if they are aware of high quality, facilitated support groups you could join.",
      "Therapists are also useful to help problem solve solutions to social support challenges. If that is feasible for you, please ask your doctor or healthcare provider for a referral or consider one of the therapists available through SupportedPatient. It is important to ask potential therapists if they have experience treating individuals with chronic illnesses as they will be more familiar with some of the unique challenges you may be facing.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  Finances: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "You have indicated that you have some financial barriers which may impact your treatment.  This may be related to your ability to afford your co-pays and deductibles or it may be related to other financial stressors.",
      "Your doctor and healthcare team would like to try to help, as your treatment recommendations will not be effective if you can't afford to buy the medicine!",
      "Please discuss your financial struggles with your doctor or healthcare team and see if the treatments they are recommending are affordable. If not, ask them to consider less expensive options. If they really feel that the treatment they are recommending is far better than less expensive options, problem solve ways to get financial assistance through pharmaceutical companies, faith-based organizations, or from supportive friends or family.",
      "We understand that asking for assistance may be unpleasant, but you have to remember that your health care needs are of utmost importance. If there really are no financial resources available to you after exploring options, ask your doctor to recommend a treatment plan that you can afford. One additional option is participating in a clinical trial, where medications would be provided to you free of charge. Ask your doctor if that is an option.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  Friends: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "Social support is very helpful for successful adaptation to illness. However, you have indicated that you do not have enough support from your family and/or your friends.",
      "We understand that illness can be isolating and your doctor and healthcare team would like to try to help.  We understand that when you disclose your illness condition to others, people react in different ways, and not all of those ways are helpful or supportive. It is challenging to manage so many changing social dynamics and there is very little if any education on how to adjust to and navigate the changing relationship dynamics.",
      "It is important to make sure that you have enough support, which can help with feelings of anxiety, depression and social isolation.",
      "There are some high quality, facilitated online patient and disease specific communities. It is worth seeking out support from others who understand your illness experience. Ask your doctor or healthcare team if they are aware of high quality, facilitated support groups you could join.",
      "Therapists are also useful to help problem solve solutions to social support challenges. If that is feasible for you, please ask your doctor or healthcare provider for a referral or consider one of the therapists available through SupportedPatient. It is important to ask potential therapists if they have experience treating individuals with chronic illnesses as they will be more familiar with some of the unique challenges you may be facing.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  HealthDiseaseSeverity: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "You have indicated that the severity of your disease or managing multiple medical conditions is a challenge for you. That is understandable and your doctor and healthcare team would like to help.",
      "Please talk to your doctor or healthcare team about what your feelings about your disease, and where your disease is significantly impacting your life. When you have severe disease  there is often impact to many parts of your life, including changing relationships with family, friends and intimate partners. Depending on your condition, your disease may impact your body image and it may impact your ability to be employed.",
      "Please share these challenges with your doctor or healthcare team.",
      "Support and therapy would likely benefit you and they can refer you to therapists or support groups which may be able to help you.",
      "It is important to ask potential therapists if they have experience treating individuals with chronic illness as they will be more familiar with the unique challenges that you may be facing.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  HealthMDPatientRelationship: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  HealthMDPtRelationship: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  HealthInsuranceBarriers: {
    minVal: 15,
    maxVal: 20,
    prompt: [
      "You have indicated that your health insurance poses barriers to treatment. Being able to obtain the care you need is a high priority for your doctor and healthcare team.  Please talk to them about the stability and sufficiency of your coverage, and what your specific challenges are.",
      "In some situations, you may be able to problem solve and obtain long term affordable coverage, where you will have sufficient expertise to manage your health condition. If you require expertise that your doctor can provide, but you may lose the ability to see that doctor when you change insurance coverage, ask them to consider the option of working together with another doctor who will be covered on a more affordable plan. Many doctors will do this and understand the challenges of health insurance coverage.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  Insurance: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  PatientDrugUseOther: {
    minVal: 15,
    maxVal: 20,
    prompt: [
      "You have indicated that your health insurance poses barriers to treatment. Being able to obtain the care you need is a high priority for your doctor and healthcare team.  Please talk to them about the stability and sufficiency of your coverage, and what your specific challenges are.",
      "In some situations, you may be able to problem solve and obtain long term affordable coverage, where you will have sufficient expertise to manage your health condition. If you require expertise that your doctor can provide, but you may lose the ability to see that doctor when you change insurance coverage, ask them to consider the option of working together with another doctor who will be covered on a more affordable plan. Many doctors will do this and understand the challenges of health insurance coverage.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  PatientHighRiskLife: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "We are concerned about your inability to meet your basic needs of food, shelter and/or safety.  This is likely to impact your health and we would like to assist you with this if possible.",
      "Please let your provider or healthcare team know which barrier you are facing.  Do you have insufficient amounts of food?  Do you have a safe place to live? Are you concerned for your physical safety?",
      "If you do not have adequate food, please ask your doctor or healthcare team to refer you to a local welfare agency, where you can receive Food Stamps and other resources.",
      "If they do not have adequate shelter, there are local shelters which may be an option. Your doctor or healthcare team can help you identify possible shelter options with family and/or friends.",
      "If you are not safe, please let your doctor or healthcare team know the type of risk you are facing. We understand that this may be hard or uncomfortable to do, but your safety is our greatest concern.",
      "Is the risk related to an unsafe neighborhood where you live or a dangerous person in your life? If it is the latter, there are shelters and domestic violence agencies that are trained to help people in your situation. If you have experienced domestic violence, please let your doctor or healthcare team know. They can help you find resources to help you stay safe.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  PatientMarijuanaUse: {
    minVal: 15,
    maxVal: 20,
    prompt: [
      "Please be thoughtful. You have indicated that you are engaging in some behaviors that we think are not good for your health. The behaviors you indicated include one or more of the following:",
      "a.   Alcohol use/abuse",
      "b.     Marijuana ",
      "c.   Smoking",
      "Please discuss these issues with your doctor or healthcare team.",
      "If you are drinking a lot of alcohol, this can pose serious health problems. Please discuss the following options with your doctor or healthcare team:",
      "1. Alcoholics Anonymous or another comparable organization that can help you quit.",
      "2. Please discuss any medication interactions with alcohol. Alcohol may make some of your medications ineffective. Also, the interactions with alcohol and some of your medications may be dangerous. Please be honest with your provider about your alcohol use. We want you to be safe!",
      "If you are smoking marijuana, discuss the reasons with your doctor or healthcare team. If you are smoking to alleviate pain or increase hunger, please discuss alternatives to pain management and increased caloric intake. While marijuana use may be legal in some states, it doesn't come without health risks.",
      "If you are smoking cigarettes, we would really like to see you stop. There are countless health problems that will be made worse due to smoking. Please discuss smoking cessation options with your doctor or healthcare provider and make a commitment to yourself to quit!",
    ],
    Score: 0,
    seePrompt: "no",
  },
  PatientOpiateUse: {
    minVal: 10,
    maxVal: 20,
    prompt: [
      "You have indicated that you are using narcotic pain medications. We are concerned because they are quite addictive and may have short and long term serious side effects.",
      "We understand that your pain is real and we do not want to minimize the severity of it. We would like to work with you to determine the source of your pain and together determine the most effective treatment.",
      "It is important to understand that there is pain that comes from the body and pain that comes from the brain. These two sources generate pain, but the treatments differ. If your pain is coming from the body, such as from inflammation due to disease or a surgery, then narcotics can be a very effective short term pain management strategy. We don't like to use opiates long term because they can have serious side effects, and can actually increase your pain instead of decrease it.",
      "However, if you are in remission, or your pain doesn't correlate with your doctor's findings, it is important to understand that pain generated from the brain is just as painful, but needs to be treated differently. Pain coming from the brain can be treated with psychosocial interventions and therapy. Please discuss options and referrals with your doctor or healthcare team.",
    ],
    Score: 0,
    seePrompt: "no",
  },
  PatientPsychiatricComorbidities: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  PatientSmoking: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  ReligionCulture: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
  Work: {
    minVal: 0,
    maxVal: 0,
    prompt: [""],
    Score: 0,
    seePrompt: "no",
  },
};

//

const Questions = {
  q1: "Do you have adequate access to food, shelter and safety?",
  q2: "Do you use prescription pain medications to manage pain including narcotics or opiates (eg. Percocet, Vicodin, Hydrocodone, Dilaudid, OxyContin, Tramadol, Tylenol with Codeine etc)",
  q3: "Do you use marijuana to manage pain?",
  q4: "Do you use street drugs such as cocaine or heroin to manage pain?",
  q5: "On any single occasion during the past three months, have you had more than five drinks containing alcohol?",
  q6: "Do you have thoughts of suicide?",
  q7: "Do you smoke?",
  q8: "Do you worry or feel anxious about the long-term impact of your medical treatment and illness?",
  q9: "Do you feel distressed when you think about your disease and how it impacts your life?",
  q10: "Do you delay seeking medical care when you need it because of previous traumatic experiences?",
  q11: "Do you have practical support for activities of daily living (eg. food shopping, errands, transportation to medical appointments etc)?",
  q12: "Do you feel alone or as though you don't have people you can talk to about your illness?",
  q13: "Do you have agreement from your family and friends about the medical treatment that your doctor is recommending?",
  q14: "Does your disease prevent you from developing meaningful relationships?",
  q15: "Do you work outside the house?",
  q16: "Do you not work outside the home because of your illness or illness related issues?",
  q17: "Do you go to school?",
  q18: "Can you obtain the medical care you need with your current health insurance?",
  q19: "How frequently do you need to change your health insurance?",
  q20: "Can you afford your medical care and treatments that your doctor recommends?",
  q21: "Do you have religious or cultural beliefs that are difficult to discuss with your doctor or for your healthcare team to understand?",
  q22: "Do you have religious or cultural beliefs that prevent you from following the treatment your doctor recommends?",
  q23: "Can you trust your doctor  to do what is in your interests?",
  q24: "Do you have sufficient access and communication with your medical team that enables your needs to be met?",
  q25: "How often have your disease symptoms been controlled over the past year?",
  q26: "Do you have more than one chronic illness (including mental illness) that requires ongoing treatment?",
  q27: "Do you worry about maintaining your current job?",
  q28: "Do you worry about getting promotions?",
  q29: "Do you have the ability to obtain accommodations for your illness related needs at your workplace?",
  q30: "Are you at a disadvantage in your job due to your illness or related issues?",
};

function prompts(sResults) {
  //Family Category Q11, Q13
  ReissCategoryPrompt.Family.Score =
    +sResults.Q11.split(",")[0] + +sResults.Q13.split(",")[0];

  ReissCategoryPrompt.Family.Questions = [Questions.q11, Questions.q13];
  ReissCategoryPrompt.Family.Answers = [
    sResults.Q11.split(",")[1],
    sResults.Q13.split(",")[1],
  ];
  if (
    ReissCategoryPrompt.Family.Score >= ReissCategoryPrompt.Family.minVal &&
    ReissCategoryPrompt.Family.Score <= ReissCategoryPrompt.Family.maxVal
  ) {
    ReissCategoryPrompt.Family.seePrompt = "yes";
  }

  //Finances Category Q20
  ReissCategoryPrompt.Finances.Score = +sResults.Q20.split(",")[0];

  ReissCategoryPrompt.Finances.Questions = [Questions.q20];
  ReissCategoryPrompt.Finances.Answers = [sResults.Q20.split(",")[1]];
  if (
    ReissCategoryPrompt.Finances.Score >= ReissCategoryPrompt.Finances.minVal &&
    ReissCategoryPrompt.Finances.Score <= ReissCategoryPrompt.Finances.maxVal
  ) {
    ReissCategoryPrompt.Finances.seePrompt = "yes";
  }

  //Friends Q12, Q14
  ReissCategoryPrompt.Friends.Score =
    +sResults.Q12.split(",")[0] + +sResults.Q14.split(",")[0];

  ReissCategoryPrompt.Friends.Questions = [Questions.q12, Questions.q14];
  ReissCategoryPrompt.Friends.Answers = [
    sResults.Q12.split(",")[1],
    sResults.Q14.split(",")[1],
  ];
  if (
    ReissCategoryPrompt.Friends.Score >= ReissCategoryPrompt.Friends.minVal &&
    ReissCategoryPrompt.Friends.Score <= ReissCategoryPrompt.Friends.maxVal
  ) {
    ReissCategoryPrompt.Friends.seePrompt = "yes";
  }

  //Health - disease severity Category Q25 Q26
  ReissCategoryPrompt.HealthDiseaseSeverity.Score =
    +sResults.Q25.split(",")[0] + +sResults.Q26.split(",")[0];
  ReissCategoryPrompt.HealthDiseaseSeverity.Questions = [
    Questions.q25,
    Questions.q26,
  ];
  ReissCategoryPrompt.HealthDiseaseSeverity.Answers = [
    sResults.Q25.split(",")[1],
    sResults.Q26.split(",")[1],
  ];
  if (
    ReissCategoryPrompt.HealthDiseaseSeverity.Score >=
      ReissCategoryPrompt.HealthDiseaseSeverity.minVal &&
    ReissCategoryPrompt.HealthDiseaseSeverity.Score <=
      ReissCategoryPrompt.HealthDiseaseSeverity.maxVal
  ) {
    ReissCategoryPrompt.HealthDiseaseSeverity.seePrompt = "yes";
  }

  //Health - MD-Patient relationship Q23
  ReissCategoryPrompt.HealthMDPatientRelationship.Score =
    +sResults.Q23.split(",")[0];
  ReissCategoryPrompt.HealthMDPatientRelationship.Questions = [Questions.q23];
  ReissCategoryPrompt.HealthMDPatientRelationship.Answers = [
    sResults.Q23.split(",")[1],
  ];

  //Health - MD-Pt Relationship	 Q24
  ReissCategoryPrompt.HealthMDPtRelationship.Score =
    +sResults.Q24.split(",")[0];
  ReissCategoryPrompt.HealthMDPtRelationship.Questions = [Questions.q24];
  ReissCategoryPrompt.HealthMDPtRelationship.Answers = [
    sResults.Q24.split(",")[1],
  ];

  //Health Insurance Barriers		Q19
  ReissCategoryPrompt.HealthInsuranceBarriers.Score =
    +sResults.Q19.split(",")[0];

  ReissCategoryPrompt.HealthInsuranceBarriers.Questions = [Questions.q19];
  ReissCategoryPrompt.HealthInsuranceBarriers.Answers = [
    sResults.Q19.split(",")[1],
  ];
  if (
    ReissCategoryPrompt.HealthInsuranceBarriers.Score >=
      ReissCategoryPrompt.HealthInsuranceBarriers.minVal &&
    ReissCategoryPrompt.HealthInsuranceBarriers.Score <=
      ReissCategoryPrompt.HealthInsuranceBarriers.maxVal
  ) {
    ReissCategoryPrompt.HealthInsuranceBarriers.seePrompt = "yes";
  }

  //Insurance Q18
  ReissCategoryPrompt.Insurance.Score = +sResults.Q18.split(",")[0];
  ReissCategoryPrompt.Insurance.Questions = [Questions.q18];
  ReissCategoryPrompt.Insurance.Answers = [sResults.Q18.split(",")[1]];

  //Patient - Drug use other Q4, Q5
  ReissCategoryPrompt.PatientDrugUseOther.Score =
    +sResults.Q4.split(",")[0] + +sResults.Q5.split(",")[0];
  ReissCategoryPrompt.PatientDrugUseOther.Questions = [
    Questions.q4,
    Questions.q5,
  ];
  ReissCategoryPrompt.PatientDrugUseOther.Answers = [
    sResults.Q4.split(",")[1],
    sResults.Q5.split(",")[1],
  ];
  if (
    ReissCategoryPrompt.PatientDrugUseOther.Score >=
      ReissCategoryPrompt.PatientDrugUseOther.minVal &&
    ReissCategoryPrompt.PatientDrugUseOther.Score <=
      ReissCategoryPrompt.PatientDrugUseOther.maxVal
  ) {
    ReissCategoryPrompt.PatientDrugUseOther.seePrompt = "yes";
  }

  //Patient - High risk life	Q1
  ReissCategoryPrompt.PatientHighRiskLife.Score = +sResults.Q1.split(",")[0];

  ReissCategoryPrompt.PatientHighRiskLife.Questions = [Questions.q1];
  ReissCategoryPrompt.PatientHighRiskLife.Answers = [sResults.Q1.split(",")[1]];
  if (
    ReissCategoryPrompt.PatientHighRiskLife.Score >=
      ReissCategoryPrompt.PatientHighRiskLife.minVal &&
    ReissCategoryPrompt.PatientHighRiskLife.Score <=
      ReissCategoryPrompt.PatientHighRiskLife.maxVal
  ) {
    ReissCategoryPrompt.PatientHighRiskLife.seePrompt = "yes";
  }

  //Patient - Marijuana use		Q3
  ReissCategoryPrompt.PatientMarijuanaUse.Score = +sResults.Q3.split(",")[0];

  ReissCategoryPrompt.PatientMarijuanaUse.Questions = [Questions.q3];
  ReissCategoryPrompt.PatientMarijuanaUse.Answers = [sResults.Q3.split(",")[1]];
  if (
    ReissCategoryPrompt.PatientMarijuanaUse.Score >=
      ReissCategoryPrompt.PatientMarijuanaUse.minVal &&
    ReissCategoryPrompt.PatientMarijuanaUse.Score <=
      ReissCategoryPrompt.PatientMarijuanaUse.maxVal
  ) {
    ReissCategoryPrompt.PatientMarijuanaUse.seePrompt = "yes";
  }

  //Patient - Opiate use		Q2
  ReissCategoryPrompt.PatientOpiateUse.Score = +sResults.Q2.split(",")[0];

  ReissCategoryPrompt.PatientOpiateUse.Questions = [Questions.q2];
  ReissCategoryPrompt.PatientOpiateUse.Answers = [sResults.Q2.split(",")[1]];
  if (
    ReissCategoryPrompt.PatientOpiateUse.Score >=
      ReissCategoryPrompt.PatientOpiateUse.minVal &&
    ReissCategoryPrompt.PatientOpiateUse.Score <=
      ReissCategoryPrompt.PatientOpiateUse.maxVal
  ) {
    ReissCategoryPrompt.PatientOpiateUse.seePrompt = "yes";
  }

  //Patient - Psychiatric comorbidities Q6, Q8, Q9, Q10
  ReissCategoryPrompt.PatientPsychiatricComorbidities.Score =
    +sResults.Q6.split(",")[0] +
    +sResults.Q8.split(",")[0] +
    +sResults.Q9.split(",")[0] +
    +sResults.Q10.split(",")[0];
  ReissCategoryPrompt.PatientPsychiatricComorbidities.Questions = [
    Questions.q6,
    Questions.q8,
    Questions.q9,
    Questions.q10,
  ];
  ReissCategoryPrompt.PatientPsychiatricComorbidities.Answers = [
    sResults.Q6.split(",")[1],
    sResults.Q8.split(",")[1],
    sResults.Q9.split(",")[1],
    sResults.Q10.split(",")[1],
  ];

  //Patient - Smoking		Q7
  ReissCategoryPrompt.PatientSmoking.Score = +sResults.Q7.split(",")[0];
  ReissCategoryPrompt.PatientSmoking.Questions = [Questions.q7];
  ReissCategoryPrompt.PatientSmoking.Answers = [sResults.Q7.split(",")[1]];

  //Religion/Culture		Q21, Q22
  ReissCategoryPrompt.ReligionCulture.Score =
    +sResults.Q21.split(",")[0] + +sResults.Q22.split(",")[0];
  ReissCategoryPrompt.ReligionCulture.Questions = [
    Questions.q21,
    Questions.q22,
  ];
  ReissCategoryPrompt.ReligionCulture.Answers = [
    sResults.Q21.split(",")[1],
    sResults.Q22.split(",")[1],
  ];

  //Work Q15, Q27, Q28, Q29, Q30
  ReissCategoryPrompt.Work.Score =
    +sResults.Q15.split(",")[0] +
    +sResults.Q27.split(",")[0] +
    +sResults.Q28.split(",")[0] +
    +sResults.Q29.split(",")[0] +
    +sResults.Q30.split(",")[0];
  ReissCategoryPrompt.Work.Questions = [
    Questions.q15,
    Questions.q27,
    Questions.q28,
    Questions.q29,
    Questions.q30,
  ];
  ReissCategoryPrompt.Work.Answers = [
    sResults.Q15.split(",")[1],
    sResults.Q27.split(",")[1],
    sResults.Q28.split(",")[1],
    sResults.Q29.split(",")[1],
    sResults.Q30.split(",")[1],
  ];

  console.log(ReissCategoryPrompt);
}

function Questionarie() {
  const survey = new Model(json);
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
    prompts(sender.data);
  });
  return <Survey model={survey} />;
}

export default Questionarie;

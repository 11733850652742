import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProductHeroLayout from "./modules/views/ProductHeroLayoutChatBot";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import BContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "bootstrap";

import { PopupButton } from "react-calendly";

import Button from "@mui/material/Button";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});
const backgroundImage =
  "http://ibdsfcertified.com/wp-content/themes/IBDSFCertified/images/Therapy.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const testimonials = [
  {
    id: "1",
    image: "",
    patient: "1.	Schedule with Dr. Marci Reiss $350*",
    subHeaderText: "",
    contentText: "https://calendly.com/reactivityapp/drmarcireiss",
    subContentText: [],
  },
  {
    id: "2",
    image: "",
    patient:
      "2.	Schedule with one of Dr. Reiss’s trained and certified experts $200*",
    subHeaderText: "",
    contentText: "https://calendly.com/d/zkx-7xn-6tw/certified-experts-200",
    subContentText: [],
  },
  {
    id: "3",
    image: "",
    patient: "3.	Schedule with one of our trainees $150*",
    subHeaderText: "",
    contentText: "https://calendly.com/d/zx5-qkx-g94/trainees-150",
    subContentText: [],
  },
];

export default function ContactAnExpert({ nextStep, steps, triggerNextStep }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const nextStepTrigger = () => {
    triggerNextStep({
      trigger: nextStep,
    });
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "transparent",
            textAlign: "center",
          }}
        >
          <Container maxWidth={false} sx={{ maxWidth: "100%" }}>
            <ul
              style={{
                listStyle: "none",
                paddingLeft: 0,
                margin: "auto !important",
              }}
            >
              {testimonials.map((testimonial) => (
                <li key={testimonial.id}>
                  <br />
                  <BContainer>
                    <Card
                      sx={{
                        maxWidth: "100%",
                      }}
                    >
                      <Row style={{ display: "flex" }}>
                        {/* <Col sm={4} style={{ flex: 1 }}>
                        <CardMedia
                          component="img"
                          height="300"
                          image={testimonial.image}
                        />
                      </Col> */}
                        <Col sm={4} style={{ flex: 2, textAlign: "center" }}>
                          <CardContent>
                            {testimonial.patient}
                            <Typography variant="body1" color="text.secondary">
                              <PopupButton
                                url={testimonial.contentText}
                                /*
                                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                 */
                                rootElement={document.getElementById("root")}
                                text="Click here to schedule!"
                              />
                            </Typography>
                            {testimonial.subContentText.length > 0 && (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography variant="body2"></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {testimonial.subContentText.map(
                                      (subCText, index) => (
                                        <li key={index}>
                                          <Typography>{subCText}</Typography>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </CardContent>
                        </Col>
                      </Row>
                    </Card>
                  </BContainer>
                </li>
              ))}
            </ul>
            <Button
              variant="contained"
              color="success"
              onClick={nextStepTrigger}
            >
              Continue in the App
            </Button>
          </Container>
        </div>
        {/* <Background
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundColor: "#7fc7d9", // Average color of the background image.
            backgroundPosition: "center",
            position: "fixed",
          }}
        /> */}
      </div>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          opacity: 0.5,
          zIndex: -1,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </>
  );
}

import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppAppBar from "./modules/views/AppAppBar";
import AppForm from "./modules/views/AppForm";
import { email, required } from "./modules/form/validation";
import RFTextField from "./modules/form/RFTextField";
import FormButton from "./modules/form/FormButton";
import FormFeedback from "./modules/form/FormFeedback";
import withRoot from "./modules/withRoot";
import { postData } from "./Utils/Ajax";
import { useNavigate } from "react-router-dom";
import ProductHeroLayout from "./modules/views/ProductHeroLayoutChatBot";

function SignIn() {
  const navigate = useNavigate();
  const [sent, setSent] = React.useState(false);
  const url = "https://apihelper.supportedpatient.com/Reactivity/LogInUser";
  //const url = "http://localhost:25782/Reactivity/LogInUser";

  const validate = (values) => {
    const errors = required(["email", "password"], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = (values) => {
    console.log(values);
    setSent(true);
    postData(url, values).then((data) => {
      console.log(data); // JSON data parsed by `data.json()` call

      if (data.result == "Authorized") {
        //if user is registered
        //save to localStorage the Cache info from Databse
        //and redirect to Chatbot then it will begin from where it was the last time in the chatbot
        //Change the LogIn to Logout
        setSent(false);
        localStorage.setItem("ReactivityChat", data.userdata.json);
        localStorage.setItem(
          "patientObj",
          JSON.stringify({
            email: data.userdata.email,
            password: data.userdata.password,
          })
        );
        localStorage.setItem("logged", "yes");
        navigate("/");
      } else {
        alert("User is not registered !!!");
        setSent(false);
      }
    });
  };

  return (
    <>
      <ProductHeroLayout>
        <AppForm>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          {/* <Typography variant="body2" align="center">
            {"Not a member yet? "}
            <Link
              href="/premium-themes/onepirate/sign-up/"
              align="center"
              underline="always"
            >
              Sign Up here
            </Link>
          </Typography> */}

          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 1 }}
              >
                <Field
                  autoComplete="email"
                  autoFocus
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <Field
                  fullWidth
                  size="large"
                  component={RFTextField}
                  disabled={submitting || sent}
                  required
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  margin="normal"
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Sign In"}
                </FormButton>
              </Box>
            )}
          </Form>
          <Typography align="center">
            <Link underline="always" href="/ForgotPassword">
              Forgot password?
            </Link>
          </Typography>
        </AppForm>
      </ProductHeroLayout>
      {/* <AppFooter /> */}
    </>
  );
}

export default withRoot(SignIn);

import * as React from "react";

function OurTeam() {
  const team = (
    <>
      <div
        style={{
          textAlign: "center",
          textShadow: "3px 3px 3px #90c1f0",
        }}
      >
        <strong>
          <div>
            <br />
            There is power in getting the support you need. Don’t go it alone!
            <br />
            <br />
            Our team of patients, clinicians and experts is here to help you on
            this journey.
            <br />
            <br />
            <img
              height="190"
              width="100%"
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/MedicalTeam.jpg"
            ></img>
          </div>
          <a href="/TestimonialsCard">
            Click here to see or listen to other patient's experiences.
          </a>
        </strong>
      </div>
    </>
  );

  return team;
}

export default OurTeam;

import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppAppBar from "./modules/views/AppAppBar";
import AppForm from "./modules/views/AppForm";
import { email, required } from "./modules/form/validation";
import RFTextField from "./modules/form/RFTextField";
import FormButton from "./modules/form/FormButton";
import FormFeedback from "./modules/form/FormFeedback";
import withRoot from "./modules/withRoot";
import ProductHeroLayout from "./modules/views/ProductHeroLayoutChatBot";

async function sendCredentials(emailId) {
  const url =
    "https://apihelper.supportedpatient.com/Reactivity/SendCredentials?Email=";
  //const url = "http://localhost:25782/Reactivity/SendCredentials?Email=";

  let response = await fetch(url + emailId);
  let data = await response.json();

  if (data.result && data.result === "success") {
    alert("Credentials Sent, Please check your email.");
  } else {
    alert("Email is not yet registered.");
  }
}

function ForgotPassword() {
  const [sent, setSent] = React.useState(false);
  const [userId, setUserId] = React.useState("");

  const validate = (values) => {
    const errors = required(["email"], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      } else {
        setUserId(values.email);
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    setSent(true);
    sendCredentials(userId);
    setSent(false);
  };

  return (
    <>
      <ProductHeroLayout>
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Forgot your password?
            </Typography>
            <Typography variant="body2" align="center">
              {"Enter your email address below and we'll " +
                "send your credentials."}
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Field
                  autoFocus
                  autoComplete="email"
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Send"}
                </FormButton>
              </Box>
            )}
          </Form>
        </AppForm>
      </ProductHeroLayout>
    </>
  );
}

export default withRoot(ForgotPassword);

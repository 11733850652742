import * as React from "react";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";

function TryLogin({ triggerNextStep }) {
  const navigate = useNavigate();

  function handleTryLogInClick() {
    navigate("/SignIn");
  }

  function handleTryDifferentEmailAddress() {
    let patientObj = JSON.parse(localStorage.getItem("patientObj"));
    patientObj.email = "";
    localStorage.setItem("patientObj", JSON.stringify(patientObj));
    triggerNextStep({ trigger: "17" });
  }

  const logIn = (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          textAlign: "center",
          height: 140,
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>Really are you sure you haven't already signed up?</td>
            </tr>
            <tr>
              <td>
                <center>
                  <Chip
                    label="Try logging In"
                    color="primary"
                    onClick={handleTryLogInClick}
                  />
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <center>
                  <Chip
                    label="Try a different email address"
                    color="primary"
                    //onClick={() => triggerNextStep({ trigger: "17" })}
                    onClick={handleTryDifferentEmailAddress}
                  />
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  return logIn;
}

export default TryLogin;

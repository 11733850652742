import React, { createContext } from "react";
import withRoot from "../withRoot";
import EmailIcon from "@mui/icons-material/Email";
import { blue } from "@mui/material/colors";
import { email, required } from "../form/validation";
import { ThemeProvider } from "styled-components";
import { FlashOnRounded } from "@mui/icons-material";
import SelectToggleDiseases from "../components/HorizontalStepperComponents/Diseases";
import CheckUser from "../chatbot/components/CheckUser";
import TryLogin from "../chatbot/components/tryloggingin";
import SavePassword from "../chatbot/components/SavePassword";
import Terms from "../chatbot/components/Terms";
import { postData } from "../../Utils/Ajax";
import Avatar from "@mui/material/Avatar";
import Video from "./components/Video";
import VerticalLinearStepper from "../components/VerticalStepper/VerticalStepper";
import MyStoryDialog from "./components/MyStoryDialog";
import ReissSurvey from "../Survey/Survey";
import ContactAnExpert from "../../ContactAnExpert";

export const chatBotContext = createContext();

function persistChat(message) {
  //const url = "http://localhost:25782/Reactivity/PersistChat";
  const url = "https://apihelper.supportedpatient.com/Reactivity/PersistChat";

  let patObj = JSON.parse(localStorage.getItem("patientObj"));
  patObj.json = localStorage.getItem("ReactivityChat");

  localStorage.setItem("patientObj", JSON.stringify(patObj));

  postData(url, patObj).then((data) => {
    console.log(data.result); // JSON data parsed by `data.json()` call
  });

  return message;
}

function persistUserName(name) {
  //const url = "http://localhost:25782/Reactivity/PersistChat";
  const url = "https://apihelper.supportedpatient.com/Reactivity/PersistChat";

  let patObj = JSON.parse(localStorage.getItem("patientObj"));
  patObj.Name = name;

  patObj.json = localStorage.getItem("ReactivityChat");
  localStorage.setItem("patientObj", JSON.stringify(patObj));

  postData(url, patObj).then((data) => {
    console.log(data.result); // JSON data parsed by `data.json()` call
  });
}

const steps = [
  // {
  //   id: 1,
  //   message: "...",
  //   trigger: 32,
  //   hideInput: true,
  //   end: false,
  // },
  {
    id: 2,
    component: (
      <VerticalLinearStepper
        speechText="Hi there, I am excited to take you on this journey where you will learn to take back control of what’s happening in your body.  

        To start, please tell me if you have any kind of disorders in your body that you  want to change."
        videoId="A"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%231.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%231_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%231_cauca.mp4"
        nextStep={3}
      />
    ),
    //trigger: "3",
    hideInput: true,
    end: false,
  },
  {
    id: 3,
    options: [{ value: 1, label: "Yes, I do.", trigger: "3A" }],
    hideInput: true,
  },
  // {
  //   id: "3A",
  //   options: [{ value: 2, label: "Like what? ", trigger: 7 }],
  //   hideInput: true,
  // },
  {
    id: "3A",
    message: "Like what?",
    trigger: 7,
    hideInput: true,
  },
  {
    id: 7,
    options: [
      { value: 1, label: "Gastroparesis", trigger: 4 },
      { value: 2, label: "Crohn’s disease", trigger: 4 },
      { value: 3, label: "Ulcerative colitis", trigger: 4 },
      { value: 4, label: "Irritable Bowel Syndrome", trigger: 4 },
      { value: 5, label: "Celiac", trigger: 4 },
      { value: 6, label: "Pain", trigger: 4 },
      { value: 7, label: "Migraine", trigger: 4 },
      { value: 8, label: "Joint Inflammation", trigger: 4 },
      { value: 9, label: "Psoriasis", trigger: 4 },
      { value: 10, label: "Misophonia", trigger: 4 },
      { value: 11, label: "PTSD", trigger: 4 },
      { value: 12, label: "Sjogren Syndrome", trigger: 4 },
    ],
    hideInput: true,
  },
  {
    id: 4,
    component: (
      <VerticalLinearStepper
        speechText="We imagine things have been challenging. It’s time to turn things around. We're looking forward to working with you and we're really excited to take you on this journey."
        videoId="B"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%232_d.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%232_d_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%232_d_cauca.mp4"
        nextStep={6}
      />
    ),
    hideInput: true,
  },
  {
    id: 6,
    options: [{ value: 1, label: "Sounds good.", trigger: 8 }],
    hideInput: true,
  },
  {
    id: 8,
    component: (
      <VerticalLinearStepper
        speechText="We want to share with you a little bit about us. We want every patient to feel well, to be supported, 
      to have the best access to the best treatment. 
      More than anything, We don’t want people to waste their money on treatments that don’t work. 
      We don’t want people to see therapists that don’t understand. 
      We don’t want people to take medications unnecessarily.
      See Dr. Marci Reiss long medical journey which has made all this very passionate about patient care"
        videoId="C"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%233_d.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%233_d_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%233_d_cauca.mp4"
        nextStep={9}
      />
    ),
    // component: (
    //   <>
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       <h5>
    //         {" "}
    //         We want to share with you a little bit about us. I have gone
    //         through&nbsp;
    //         <a href="https://drmarcireiss.com/my-story/">
    //           my own long medical journey{" "}
    //         </a>
    //         which has made me very passionate about patient care. I want every
    //         patient to feel well, to be supported, to have the best access to
    //         the best treatment. More than anything, I don’t want people to waste
    //         their money on treatments that don’t work. I don’t want people to
    //         see therapists that don’t understand. I don’t want people to take
    //         medications unnecessarily.{" "}
    //       </h5>
    //     </div>
    //   </>
    // ),
    hideInput: true,
    //trigger: 9,
  },
  {
    id: 9,
    component: <MyStoryDialog />,
    hideInput: true,
    trigger: "9A",
    end: false,
  },
  {
    id: "9A",
    options: [{ value: 1, label: "Continue...", trigger: "10A" }],
    hideInput: true,
    end: false,
  },
  {
    id: "10A",
    component: (
      <VerticalLinearStepper
        speechText="We have worked with countless patients over the years and have built models of patient care at many hospitals in the US including at the Mayo Clinic, Vanderbilt University, University of Maryland Baltimore, and University of California San Diego. 

      On the home page of www.ibdsf.org you can see some of the trainings we have done for therapists and medical professionals. 
      
      We believe that there is value to both western medicine and a more holistic approach and it is the marriage of those two that makes magic for the patient."
        videoId="D"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%233_2_d.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%233_2_dd_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%233_2_dd_cauca.mp4"
        nextStep={"10AA"}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: "10AA",
    component: (
      <>
        <div
          style={{
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
            alignText: "center",
            textAlign: "center",
          }}
        >
          <strong>
            Take a look at: <br />
            <a href="https://www.ibdsf.org/">www.ibdsf.org </a> <br />
            and
            <br />
            <a href="https://drmarcireiss.com/">drmarcireiss.com </a>
            <br />
          </strong>
        </div>
      </>
    ),
    hideInput: true,
    trigger: "11A",
    end: false,
  },
  {
    id: "11A",
    options: [{ value: 1, label: "Continue...", trigger: "10B" }],
    hideInput: true,
    end: false,
  },
  {
    id: "10B",
    component: (
      <VerticalLinearStepper
        speechText="Instead of using artificial intelligence to guide your journey, which lots of apps use, real clinicians will review your symptoms and challenges and create your personalized treatment plan. 

      Each of these clinicians are people that Dr. Marci Reiss has personally trained and certified, and that are knowledgeable in how to get your body to work for you.
      
      Please join us and let us help guide you on your road to health and wellness."
        videoId="E"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%233_3_d.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%233_3_d%20_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%233_3_d_cauca.mp4"
        nextStep={12}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: 12,
    options: [{ value: 1, label: "Count me in!", trigger: "13A" }],
    hideInput: true,
    end: false,
  },
  {
    id: "13A",
    component: (
      <VerticalLinearStepper
        videoId="F"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video%20%234.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video%20%234_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video%20%234_cauca.mp4"
        nextStep={13}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: 13,
    component: (
      <>
        <div
          style={{
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
            alignText: "center",
            textAlign: "center",
          }}
        >
          <h3>Wonderful! Let’s get started….</h3>
          <h5>
            {" "}
            &nbsp;&nbsp;&nbsp;We understand that people sometimes hesitate about
            signing up for a new program or app. If you share your email with us
            now, we can save your spot as you progress. We won’t share your
            email with others or send you anything unnecessary.{" "}
          </h5>
        </div>
      </>
    ),
    hideInput: true,
    trigger: 14,
    end: false,
  },
  {
    id: 14,
    options: [{ value: 1, label: "Sounds good.", trigger: 17 }],
    hideInput: true,
    end: false,
  },
  // {
  //   id: 15,
  //   message: "What is your email?",
  //   trigger: 17,
  //   hideInput: true,
  //   end: false,
  // },
  // {
  //   id: "16",
  //   user: true,
  //   validator: (value) => {
  //     const errors = required(["email"], value);
  //     console.log(errors.email);

  //     if (errors.email) {
  //       const emailError = email(value);
  //       console.log(emailError);
  //       if (emailError) {
  //         errors.email = emailError;
  //         return errors.email;
  //       } else {
  //         return true;
  //       }
  //     }
  //   },
  //   trigger: "17",
  // },
  {
    id: 17,
    component: <CheckUser />,
    hideInput: true,
    waitAction: true,
  },

  {
    id: 18,
    message: "Great – check your email for a message from Reactivitymodel.",
    trigger: 19,
    hideInput: true,
    end: false,
  },
  // {
  //   id: 19,
  //   message: "What would you like to user as a password to log back in?",
  //   trigger: 20,
  //   end: false,
  // },
  // {
  //   id: 20,
  //   user: true,
  //   inputAttributes: {
  //     type: "password",
  //   },
  //   validator: (value) => {
  //     console.log(value.length);
  //     if (value.length === 0) {
  //       return "Type a Password";
  //     } else {
  //       return true;
  //     }
  //   },
  //   //trigger: "17",
  //   end: false,
  // },

  {
    id: 19,
    component: <SavePassword />,
    hideInput: true,
    waitAction: true,
  },
  {
    id: "reallySignUp",
    component: <TryLogin />,
    waitAction: true,
    hideInput: true,
    //trigger: "",
    end: false,
  },
  {
    id: 20,
    message: "Great. Now that you’re signed in, we can begin.",
    trigger: 21,
    hideInput: true,
    end: false,
  },
  {
    id: 21,
    message:
      "First and foremost, I want to make sure that you have consulted with a doctor about any symptoms you are having that could be life-threatening.",
    trigger: 22,
    hideInput: true,
    end: false,
  },
  {
    id: 22,
    component: <Terms />,
    trigger: 23,
    hideInput: true,
    end: false,
  },
  {
    id: 23,
    options: [{ value: 1, label: "Accept terms..", trigger: 24 }],
    hideInput: true,
    end: false,
  },
  {
    id: 24,
    message: "Now we’re going to start our journey.",
    trigger: 25,
    hideInput: true,
    end: false,
  },
  {
    id: 25,
    message: "What’s your first name? ",
    trigger: 26,
  },
  {
    id: 26,
    user: true,
    validator: (value) => {
      persistUserName(value);
      return true;
    },
    trigger: () => {
      return persistChat(27);
    },
  },
  {
    id: 27,
    message: "Nice to meet you {previousValue}. You’re all set.",
    hideInput: true,
    trigger: 28,
  },
  {
    id: 28,
    message:
      "To begin, I would like to get to know you. It’s interesting that our past life experiences really impact our health – particularly the things in our pasts that we would prefer to forget! ",
    hideInput: true,
    trigger: 29,
  },
  {
    id: 29,
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat(30);
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: 30,
    message:
      "The best way to get a full picture of your history that is contributing to your health issues now, is with a comprehensive conversation with one of our experts. This can be done by zoom in the comfort of your home or office at a time that is convenient for you.",
    trigger: () => {
      return persistChat(31);
    },
    hideInput: true,
  },
  {
    id: 31,
    message: "What would you like to do next?",
    trigger: () => {
      return persistChat(32);
    },
    hideInput: true,
  },
  {
    id: 32,
    options: [
      {
        value: 1,
        label: "Continue in the App",
        trigger: () => {
          return persistChat(33);
        },
      },
      {
        value: 2,
        label: "Contact an Expert",
        trigger: () => {
          return persistChat(34);
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  // {
  //   id: 33,
  //   message: () => {
  //     return persistChat("Ok Let's continue with the App");
  //   },
  //   trigger: () => {
  //     return persistChat("Survey");
  //   },
  //   hideInput: true,
  // },
  {
    id: 34,
    component: <ContactAnExpert nextStep={33} />,
    hideInput: true,
  },
  {
    id: 33,
    component: (
      <VerticalLinearStepper
        speechText="Okay, here are a list of questions that will be reviewed carefully by our team. We will then design a program that we think will help you change what’s happening in your body. "
        videoId="G"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_8.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_8_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_8_cauca.mp4"
        nextStep={"Survey"}
      />
    ),
    //trigger: "3",
    hideInput: true,
    end: false,
  },
  {
    id: "Survey",
    component: <ReissSurvey nextStep={36} />,
    hideInput: true,
    end: false,
  },
  {
    id: 35,
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat(36);
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: 36,
    component: (
      <VerticalLinearStepper
        speechText="Thank you for providing us with your history which will help us make this program specifically designed for you. We are now ready to begin."
        videoId="H"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_9.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_9_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_9_cauca.mp4"
        nextStep={"37A"}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: "37A",
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat("37B");
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: "37B",
    component: (
      <VerticalLinearStepper
        speechText="First, I want you to know that even though sometimes you might feel like nothing can help and no one can figure out what’s really wrong with you, nothing you have shared makes me think you are that “one off” individual that this model won’t work for. Everyone is unique but the patterns that drive illness really seem to be the same. I am confident that we can help you.

        When you think about medications and how medications work….well, if we get the diagnosis right then there is often a large portion of patients that a specific medication will help. It stops the brain or the body from doing what it is trying to do that is pathological or harmful. If we get the diagnosis wrong, then you’re going to get all of the drug side effects and none of the benefits because you don’t have what the drug is treating. 
        
        Now, let’s think about best case scenario, your doctor has the right diagnosis and wants you to take a medicine. Sounds reasonable right? It is, especially in situations where the individual is battling serious disease. But the reality is, there is pain that comes from the body, and pain that comes from the brain. So even if the medication is treatment the pain that comes from inflammation or injury, there is still the brain that drives pain and that part, we can pretty much always improve.
        
        Think about it, if you break your leg, there is the pain that comes from the fracture – you have a structural injury and there is probably a lot of surrounding inflammation. But even in the case of pain from a broken leg, there is an added piece of pain that comes from the brain. What does that mean? Well, anxiety and depression elevate pain perception. That doesn’t mean it is in your head. It means, that the brain is driving that part of the pain.
        
        So if you spend time worrying about how long you’re going to be in a cast, who is going to help you get groceries or get to work or help with your kids while you’re in that cast, there is going to be a component of pain that is being driven by the brain. So even in the case of a broken leg, we can actually improve the pain you’re experiencing.
        
        
         and it is the source of that pain that should be determining the approach to treatment.
        
        "
        videoId="I"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_10_A.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_10_A_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_10_A_cauca.mp4"
        nextStep={"38A"}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: "38A",
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat("38B");
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: "38B",
    component: (
      <VerticalLinearStepper
        speechText="We are about to begin your journey to wellness. The first thing we are going to do is a visualization exercise that I call, Discovery.

        I want you to get comfortable. Be seated in any comfortable space. You can have your eyes opened or closed, whatever makes you comfortable. Have your hands resting in your lap, just not touching each other. As you’re sitting comfortably, I want you to visualize yourself standing on the edge of a field. 
        
        Breathe comfortable and imagine the large open green grassy field in front of you. It’s a beautiful sunny day. The sun is shining. You can feel its warmth on your face…not too hot, just warm. Your eyes are closed. There is a slight breeze that you can feel gently blowing through your hair.
        
        I want you to see yourself standing on the edge of the field, and where you are at the edge of the field, is where you are today – with all of the pain, the worry, the fears, the feelings of being stuck, the panic, the stress….and as you look into the field, there is another you about 15 feet into the field….and that you is calm, relaxed, pain free, worry free, truly content, at peace. That you is light as a feather.
        
        I am going to tell you now to go from the you on the edge of the field to the you fifteen feet into the field…what comes up for you? Please just write down your thoughts….can you reach the you in the field? What is between the you on the edge of the field and the you inside the field? Is it emotions? Is it people? It is circumstances? Please describe fully out loud to yourself what you’re thinking and feeling when I tell you to go into the field"
        videoId="J"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_11_A.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_11_A_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_11_A_cauca.mp4"
        nextStep={"39A"}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: "39A",
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat("39B");
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: "39B",
    component: (
      <VerticalLinearStepper
        speechText="Now that we have gotten to know each other a bit, I want you to help me out with something. After I record this session, I need to head out for a few hours. I actually need directions to where I am going. Can you please put into the text box, the directions for me. I’m going to pause and wait for you to do this.

        Are you done yet? I can wait. Please put into the text box, the directions for me to get where I’m going this afternoon.
        
        Struggling with how to help? Of course you are, because you’re missing some key pieces of information. 
        
        Let’s try this again. I didn’t mention it, but I’m in Los Angeles and I need to head downtown. Can you please put into the text box some clear instructions for me to get downtown?
        
        Still struggling with how to help? Well, you should be because you’re still missing some key pieces of information. Let’s try this one more time.
        
        I’m at Universal Studios in Los Angeles and I need to get to the Staples Center in downtown Los Angeles. Can you help now? 
        
        Sure you can! This should be much easier because you know where I’m starting and you know exactly where I want to go.
        
        This journey is similar to mine – if you have a clear picture of where you want to go, I can help you get there. If you don’t, it will be a whole lot harder. And it isn’t just for me to help you – in order to learn to have your brain work for you, you need to be giving it clear instructions on where you want it to go. It may sound simplistic – you can’t just tell your brain “make me healthy and out of pain” and expect magic. It IS a journey, but unless you have a very clear picture of where you want to go, your brain simply can’t be expected to get you there.
        
        This brings us to our first homework exercise. If you wanted to guide your brain and give it directions to where it should take you in the different parts of your life, what would you say? Would you tell your brain to make your rich or poor? Married or single? Children or no children? 
        
        In this homework exercise, I want you to spend some time thinking about how you are going to tell your brain to build you the life that you want. On your app, you will see different domains or categories of life. I want you to spend time thinking about and really visualizing the life you want in each of these domains. You should visualizing things that are realistic. For example, saying you want to be a brain surgeon when you’re 50 and you haven’t yet finished high school is somewhat unrealistic. Stating that you want to go back and finish your degree and even then attend college are goals that are more realistic. Saying that you want to be an Olympic ice skater but are a paraplegic is unrealistic. Saying you want to be a special Olympics Olympian is more realistic.
        "
        videoId="K"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_12_A.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_12_A_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_12_A_cauca.mp4"
        nextStep={"40A"}
      />
    ),
    hideInput: true,
    end: false,
  },
  {
    id: "40A",
    options: [
      {
        value: 1,
        label: "Continue..",
        trigger: () => {
          return persistChat("40B");
        },
      },
    ],
    hideInput: true,
    end: false,
  },
  {
    id: "40B",
    component: (
      <VerticalLinearStepper
        speechText="You as an Individual – what do you what your personality to be like when you’re finished with your journey? Do you want to be calmer, happier, more focused, more relaxed, more spontaneous, more confident? Describe attributes that you would like to attain. These could be attributes that you already have or attributes you want to achieve over time. 

        Your Family – Do you want to be single? Married? In a committed relationship? Out of a challenging relationship? What you do you want your relationship to look like with your extended family members? Do you want to have children? How many? Do you want to have pets? What kind and how many? Spend some time really visualizing and describing the family you want to build.
        
        Your Social World – Do you want to have a lot of friends? A few close friends? Do you want to be part of a particular club or social group? "
        videoId="L"
        videoUrlA="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/Video_12_B.mp4"
        videoUrlB="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/black/Video_12_B_black.mp4"
        videoUrlC="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/videos/caucasian/Video_12_B_cauca.mp4"
      />
    ),
    hideInput: true,
    end: false,
  },
];

// all available props
const theme = {
  //background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#EF6C00",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#EBA8A6",
  botFontColor: "black",
  userBubbleColor: "#9BC7EB",
  userFontColor: "black",
};

const Bot = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <chatBotContext.Provider value={steps}>
        {children}
      </chatBotContext.Provider>
    </ThemeProvider>
  );
};

export default Bot;

import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { email, required } from "../../form/validation";
// import { useNavigate } from "react-router-dom";
import { postData } from "../../../Utils/Ajax";

async function verifyEmailInDB(emailId, { triggerNextStep }) {
  console.log(emailId);

  const url = "https://apihelper.supportedpatient.com/Reactivity/Verify?id=";
  //const url = "http://localhost:25782/Reactivity/Verify?id=";

  try {
    if (verifyEmailStr(emailId)) {
      localStorage.setItem(
        "patientObj",
        JSON.stringify({ email: emailId, password: "" })
      );

      let response = await fetch(url + emailId);
      let data = await response.json();

      if (data.result && data.result === "found") {
        triggerNextStep({
          trigger: "reallySignUp",
        });
      } else {
        sendEmail(emailId);
        triggerNextStep({
          trigger: 18,
        });
      }
    } else {
      alert("Invalid Email !");
    }
  } catch (e) {
    console.log("Something went wrong", e);
  }
}

async function sendEmail(emailId) {
  const url = "https://apihelper.supportedpatient.com/Email/EmailReact";
  //const url = "http://localhost:25782/Email/EmailReact";

  let data = {
    division: "",
    name: "",
    firstname: "",
    lastname: "",
    email: emailId,
    phone: "",
    message: "",
  };

  postData(url, data).then((data) => {
    console.log(data); // JSON data parsed by `data.json()` call
  });
}

function verifyEmailStr(value) {
  const errors = required(["email"], value);
  console.log(errors.email);

  if (errors.email) {
    const emailError = email(value);
    console.log(emailError);
    if (emailError) {
      errors.email = emailError;
      return false;
    } else {
      return true;
    }
  }
}

function CheckUser({ steps, triggerNextStep }) {
  //const email = steps[16].value;
  //console.log(steps[16].value);

  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    let patObj = JSON.parse(localStorage.getItem("patientObj"));
    console.log(patObj);

    if (patObj != null) {
      setEmail(patObj.email);
    }
  }, []);

  // const navigate = useNavigate();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  function handleClick() {
    if (email !== "" && triggerNextStep !== undefined) {
      verifyEmailInDB(email, { triggerNextStep });
    } else {
      return false;
    }
  }

  const emailTextField = (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          textAlign: "center",
          height: 140,
        }}
      >
        <table
          style={{
            backgroundColor: "ivory",
            textAlign: "center",
          }}
        >
          <tbody>
            <tr>
              <td>What's your email address?</td>
            </tr>
            <tr>
              <td>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="filled"
                  onChange={handleChange}
                  value={email}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Chip label="Send" color="primary" onClick={handleClick} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  return emailTextField;
}

export default CheckUser;

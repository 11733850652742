import * as React from "react";
import Home from "./Home";
import SignIn from "./SignIn";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import withRoot from "./modules/withRoot";
import AppAppBar from "./modules/views/AppAppBar";
import Box from "@mui/material/Box";
import ChatBotView from "./modules/chatbot/ChatBotView";
import ForgotPassword from "./ForgotPassword";
import ProductCategories from "./modules/views/ProductCategories";
import TestimonialsCard from "./Testimonials";
import MyStory from "./MyStory";
import Questionarie from "./modules/Survey/Questionarie";
import ContactAnExpert from "./ContactAnExpert";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppAppBar />
        <Box
          sx={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "common.black",
            opacity: 0,
            height: 70,
            zIndex: 5,
          }}
        ></Box>
        <Routes>
          <Route path="/" element={<ChatBotView />} />
          {/* <Route path="/trytheapp" element={<ChatBotView />} /> */}
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ProductCategories" element={<ProductCategories />} />
          <Route path="/TestimonialsCard" element={<TestimonialsCard />} />
          <Route path="/MyStory" element={<MyStory />} />
          <Route path="/Questionarie" element={<Questionarie />} />
          <Route path="/ContactAnExpert" element={<ContactAnExpert />} />
        </Routes>
      </BrowserRouter>
      {/* <AppFooter /> */}
    </React.Fragment>
  );
}

export default withRoot(App);

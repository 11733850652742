import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { StepContext } from "../chatbot/ChatBotView";
import Chip from "@mui/material/Chip";
import SelectToggleDiseases from "../components/HorizontalStepperComponents/Diseases";
import WelcomeToReactApp from "../components/HorizontalStepperComponents/WelcometoReactApp";
import OurTeam from "../components/HorizontalStepperComponents/OurTeam";
import { useMediaQuery } from "react-responsive";

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const steps = [
  {
    label: "",
    description: <WelcomeToReactApp />,
    bgSrc:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/horizontalBackImage.jpg",
  },
  {
    description: <SelectToggleDiseases />,
    bgSrc:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/horizontalBackImage.jpg",
  },
  {
    description: <OurTeam />,
    bgSrc:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/horizontalBackImage.jpg",
  },
  {
    description: <Counter />,
    bgSrc:
      "https://reactivity.s3.us-west-1.amazonaws.com/chatbot/YourProgramIsReady.jpg",
  },
];

function Counter() {
  const [, setSteps] = React.useContext(StepContext);
  const chatbot = () => {
    setSteps(1);
    localStorage.setItem("horizontalStep", 1);
  };

  return (
    <>
      <div style={{ textShadow: "5px 5px 5px gray" }}>
        <strong>
          <font size={5} color="navy">
            Our professional team <br />
            will walk you through this journey.
          </font>
        </strong>
        <br />
        <br />
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            alt="Remy Sharp"
            src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/MedicalTeam.jpg"
            sx={{ width: 90, height: 90 }}
          />
        </StyledBadge>
        <br />
        <Chip label="Tap to Start." onClick={chatbot} color="info" />
      </div>
    </>
  );
}

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(maxSteps - 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {isDesktopOrLaptop && (
        <div
          style={{
            height: "auto",
            position: "",
          }}
        >
          <Box sx={{ flexGrow: 1, height: "auto", position: "relative" }}>
            {/* <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                  pl: 2,
                  bgcolor: "#68869e",
                  color: "white",
                }}
              >
                <Typography>{steps[activeStep].label}</Typography>
              </Paper> */}
            <Box
              sx={{
                height: "525px",
                width: "100%",
                p: 2,
                bgcolor: "#d0d3da",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "black",
                backgroundImage: `url(${steps[activeStep].bgSrc})`,
                backgroundSize: "cover",
              }}
            >
              {steps[activeStep].description}
              <br />
              <br />
              {/* <div>{steps[activeStep].component1}</div> */}
            </Box>
            <MobileStepper
              sx={{ bgcolor: "#d0d3da" }}
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Continue...
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              // backButton={
              //   <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              //     {theme.direction === "rtl" ? (
              //       <KeyboardArrowRight />
              //     ) : (
              //       <KeyboardArrowLeft />
              //     )}
              //     Back
              //   </Button>
              // }
            />
          </Box>
        </div>
      )}

      {isTabletOrMobile && (
        <div style={{ height: "100%", position: "" }}>
          <Box sx={{ flexGrow: 1, height: "100%", position: "relative" }}>
            {/* <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                  pl: 2,
                  bgcolor: "#68869e",
                  color: "white",
                }}
              >
                <Typography>{steps[activeStep].label}</Typography>
              </Paper> */}
            <Box
              sx={{
                height: windowSize.innerHeight - 120,
                width: "100%",
                p: 2,
                bgcolor: "#68869e",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "white",
                backgroundImage: `url(${steps[activeStep].bgSrc})`,
                backgroundSize: "cover",
              }}
            >
              {steps[activeStep].description}
              <br />
              <br />
              {/* <div>{steps[activeStep].component1}</div> */}
            </Box>
            <MobileStepper
              sx={{ bgcolor: "#68869e" }}
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Continue...
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              // backButton={
              //   <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              //     {theme.direction === "rtl" ? (
              //       <KeyboardArrowRight />
              //     ) : (
              //       <KeyboardArrowLeft />
              //     )}
              //     Back
              //   </Button>
              // }
            />
          </Box>
        </div>
      )}
    </>
  );
}

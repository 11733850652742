import * as React from "react";
import Survey from "../../Survey/Survey";

function WelcomeToReactApp() {
  const welcome = (
    <>
      <div
        style={{
          textAlign: "center",
          textShadow: "3px 3px 3px #90c1f0",
        }}
      >
        <div>
          <br />
          <br />
          <img
            height="100"
            width="100"
            src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Atom_1.0_icon.png"
          ></img>
          <br />
          <strong>
            <h2>
              <p style={{ fontFamily: "Copperplate" }}>R E A C T I V I T Y</p>
            </h2>
          </strong>
          <br />
          <br />
          Welcome to the Reactivity Model where YOU control the reactivity of
          your brain and YOU control what happens In your body.
        </div>
      </div>
    </>
  );

  return welcome;
}

export default WelcomeToReactApp;

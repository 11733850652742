import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const backgroundImage =
  "http://ibdsfcertified.com/wp-content/themes/IBDSFCertified/images/Header-Hero-Shot/Hero-Shot-Woman.jpg";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2">
        A Better Approach <br /> To Your Pain
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h4"
        marked="center"
        sx={{ mb: 1, mt: { sx: 1, sm: 2 } }}
      >
        Gastrointestinal Problems
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 2, sm: 2 } }}
      >
        You’ve tried all the medication but the pain and symptoms remain.
        <br />
        Let Dr. Reiss show you a better way.
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        marginTop={3}
      >
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              component="a"
              href="/trytheapp"
              sx={{ minWidth: 200 }}
            >
              Try the App
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ColorButton
              variant="contained"
              size="medium"
              component="a"
              href="/premium-themes/onepirate/sign-up/"
              sx={{ minWidth: 200 }}
            >
              Subscribe Now
            </ColorButton>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="body2" color="inherit" sx={{ mt: 6 }}>
        Have an account? <a href="#">Log in….</a>
      </Typography>
      <ArrowDownwardIcon />
    </ProductHeroLayout>
  );
}

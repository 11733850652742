import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SpaIcon from "@mui/icons-material/Spa";
import { useNavigate } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Avatar from "@mui/material/Avatar";

import writeXlsxFile from "write-excel-file";

const pages = [
  { name: " /  Testimonials", path: "/TestimonialsCard" },
  { name: " /  Dr. Marci Reiss story", path: "/MyStory" },
  { name: " /  Contact an Expert", path: "/ContactAnExpert" },
];
const settings = [];

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function AppAppBar(props) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [url, setUrl] = React.useState("/");

  const [logged, setLogged] = React.useState(
    () => window.localStorage.getItem("logged") ?? ""
  );

  React.useEffect(() => {
    //window.localStorage.setItem("logged", logged);
    setLogged(window.localStorage.getItem("logged") ?? "");
    console.log(logged);
  }, [url]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Questions = {
    q1: "Do you have adequate access to food, shelter and safety?",
    q2: "Do you use prescription pain medications to manage pain including narcotics or opiates (eg. Percocet, Vicodin, Hydrocodone, Dilaudid, OxyContin, Tramadol, Tylenol with Codeine etc)",
    q3: "Do you use marijuana to manage pain?",
    q4: "Do you use street drugs such as cocaine or heroin to manage pain?",
    q5: "On any single occasion during the past three months, have you had more than five drinks containing alcohol?",
    q6: "Do you have thoughts of suicide?",
    q7: "Do you smoke?",
    q8: "Do you worry or feel anxious about the long-term impact of your medical treatment and illness?",
    q9: "Do you feel distressed when you think about your disease and how it impacts your life?",
    q10: "Do you delay seeking medical care when you need it because of previous traumatic experiences?",
    q11: "Do you have practical support for activities of daily living (eg. food shopping, errands, transportation to medical appointments etc)?",
    q12: "Do you feel alone or as though you don't have people you can talk to about your illness?",
    q13: "Do you have agreement from your family and friends about the medical treatment that your doctor is recommending?",
    q14: "Does your disease prevent you from developing meaningful relationships?",
    q15: "Do you work outside the house?",
    q16: "Do you not work outside the home because of your illness or illness related issues?",
    q17: "Do you go to school?",
    q18: "Can you obtain the medical care you need with your current health insurance?",
    q19: "How frequently do you need to change your health insurance?",
    q20: "Can you afford your medical care and treatments that your doctor recommends?",
    q21: "Do you have religious or cultural beliefs that are difficult to discuss with your doctor or for your healthcare team to understand?",
    q22: "Do you have religious or cultural beliefs that prevent you from following the treatment your doctor recommends?",
    q23: "Can you trust your doctor  to do what is in your interests?",
    q24: "Do you have sufficient access and communication with your medical team that enables your needs to be met?",
    q25: "How often have your disease symptoms been controlled over the past year?",
    q26: "Do you have more than one chronic illness (including mental illness) that requires ongoing treatment?",
    q27: "Do you worry about maintaining your current job?",
    q28: "Do you worry about getting promotions?",
    q29: "Do you have the ability to obtain accommodations for your illness related needs at your workplace?",
    q30: "Are you at a disadvantage in your job due to your illness or related issues?",
  };

  async function downloadCSV() {
    const url = "https://apihelper.supportedpatient.com/Reactivity/GetReissStatistics";
    //const url = "http://localhost:25782/Reactivity/GetReissStatistics";

    try {
      let response = await fetch(url);
      let datos = await response.json();

      if (datos.result && datos.result !== "empty") {
        console.log(datos.result);

        const data = [
          [
            {
              value: "Name",
              fontWeight: "bold",
            },
            {
              value: "Email",
              fontWeight: "bold",
            },
            {
              value: "Q1",
              fontWeight: "bold",
            },
            {
              value: "Q2",
              fontWeight: "bold",
            },
            {
              value: "Q3",
              fontWeight: "bold",
            },
            {
              value: "Q4",
              fontWeight: "bold",
            },
            {
              value: "Q5",
              fontWeight: "bold",
            },
            {
              value: "Q6",
              fontWeight: "bold",
            },
            {
              value: "Q7",
              fontWeight: "bold",
            },
            {
              value: "Q8",
              fontWeight: "bold",
            },
            {
              value: "Q9",
              fontWeight: "bold",
            },
            {
              value: "Q10",
              fontWeight: "bold",
            },
            {
              value: "Q11",
              fontWeight: "bold",
            },
            {
              value: "Q12",
              fontWeight: "bold",
            },
            {
              value: "Q13",
              fontWeight: "bold",
            },
            {
              value: "Q14",
              fontWeight: "bold",
            },
            {
              value: "Q15",
              fontWeight: "bold",
            },
            {
              value: "Q16",
              fontWeight: "bold",
            },
            {
              value: "Q17",
              fontWeight: "bold",
            },
            {
              value: "Q18",
              fontWeight: "bold",
            },
            {
              value: "Q19",
              fontWeight: "bold",
            },
            {
              value: "Q20",
              fontWeight: "bold",
            },
            {
              value: "Q21",
              fontWeight: "bold",
            },
            {
              value: "Q22",
              fontWeight: "bold",
            },
            {
              value: "Q23",
              fontWeight: "bold",
            },
            {
              value: "Q24",
              fontWeight: "bold",
            },
            {
              value: "Q25",
              fontWeight: "bold",
            },
            {
              value: "Q26",
              fontWeight: "bold",
            },
            {
              value: "Q27",
              fontWeight: "bold",
            },
            {
              value: "Q28",
              fontWeight: "bold",
            },
            {
              value: "Q29",
              fontWeight: "bold",
            },
            {
              value: "Q30",
              fontWeight: "bold",
            },
          ],
        ];

        const questionsInfo = [
          [
            {
              value: "Q#",
              fontWeight: "bold",
            },
            {
              value: "Question",
              fontWeight: "bold",
            },
          ],
        ];

        questionsInfo.push([
          {
            type: String,
            value: "Q1",
          },
          {
            type: String,
            value: Questions.q1,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q2",
          },
          {
            type: String,
            value: Questions.q2,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q3",
          },
          {
            type: String,
            value: Questions.q3,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q4",
          },
          {
            type: String,
            value: Questions.q4,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q5",
          },
          {
            type: String,
            value: Questions.q5,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q6",
          },
          {
            type: String,
            value: Questions.q6,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q7",
          },
          {
            type: String,
            value: Questions.q7,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q8",
          },
          {
            type: String,
            value: Questions.q8,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q9",
          },
          {
            type: String,
            value: Questions.q9,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q10",
          },
          {
            type: String,
            value: Questions.q10,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q11",
          },
          {
            type: String,
            value: Questions.q11,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q12",
          },
          {
            type: String,
            value: Questions.q12,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q13",
          },
          {
            type: String,
            value: Questions.q13,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q14",
          },
          {
            type: String,
            value: Questions.q14,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q15",
          },
          {
            type: String,
            value: Questions.q15,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q16",
          },
          {
            type: String,
            value: Questions.q16,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q17",
          },
          {
            type: String,
            value: Questions.q17,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q18",
          },
          {
            type: String,
            value: Questions.q18,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q19",
          },
          {
            type: String,
            value: Questions.q19,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q20",
          },
          {
            type: String,
            value: Questions.q20,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q21",
          },
          {
            type: String,
            value: Questions.q21,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q22",
          },
          {
            type: String,
            value: Questions.q22,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q23",
          },
          {
            type: String,
            value: Questions.q23,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q24",
          },
          {
            type: String,
            value: Questions.q24,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q25",
          },
          {
            type: String,
            value: Questions.q25,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q26",
          },
          {
            type: String,
            value: Questions.q26,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q27",
          },
          {
            type: String,
            value: Questions.q27,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q28",
          },
          {
            type: String,
            value: Questions.q28,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q29",
          },
          {
            type: String,
            value: Questions.q29,
          },
        ]);
        questionsInfo.push([
          {
            type: String,
            value: "Q30",
          },
          {
            type: String,
            value: Questions.q30,
          },
        ]);

        datos.result.map((result) => {
          const reiss = JSON.parse(result.reiss);
          console.log(result);
          console.log(reiss);

          const DATA_ROW = [
            // "Name"
            {
              type: String,
              value: result.name,
            },
            // "Email"
            {
              type: String,
              value: result.email,
            },
            // "Q1"
            {
              type: String,
              value: reiss.AllAnswers.Q1.split(",")[1],
            },
            // "Q2"
            {
              type: String,
              value: reiss.AllAnswers.Q2.split(",")[1],
            },
            // "Q3"
            {
              type: String,
              value: reiss.AllAnswers.Q3.split(",")[1],
            },
            // "Q4"
            {
              type: String,
              value: reiss.AllAnswers.Q4.split(",")[1],
            },
            // "Q5"
            {
              type: String,
              value: reiss.AllAnswers.Q5.split(",")[1],
            },
            // "Q6"
            {
              type: String,
              value: reiss.AllAnswers.Q6.split(",")[1],
            },
            // "Q7"
            {
              type: String,
              value: reiss.AllAnswers.Q7.split(",")[1],
            },
            // "Q8"
            {
              type: String,
              value: reiss.AllAnswers.Q8.split(",")[1],
            },
            // "Q9"
            {
              type: String,
              value: reiss.AllAnswers.Q9.split(",")[1],
            },
            // "Q10"
            {
              type: String,
              value: reiss.AllAnswers.Q10.split(",")[1],
            },
            // "Q11"
            {
              type: String,
              value: reiss.AllAnswers.Q11.split(",")[1],
            },
            // "Q12"
            {
              type: String,
              value: reiss.AllAnswers.Q12.split(",")[1],
            },
            // "Q13"
            {
              type: String,
              value: reiss.AllAnswers.Q13.split(",")[1],
            },
            // "Q14"
            {
              type: String,
              value: reiss.AllAnswers.Q14.split(",")[1],
            },
            // "Q15"
            {
              type: String,
              value: reiss.AllAnswers.Q15.split(",")[1],
            },
            // "Q16"
            {
              type: String,
              value: reiss.AllAnswers.Q16.split(",")[1],
            },
            // "Q17"
            {
              type: String,
              value: reiss.AllAnswers.Q17.split(",")[1],
            },
            // "Q18"
            {
              type: String,
              value: reiss.AllAnswers.Q18.split(",")[1],
            },
            // "Q19"
            {
              type: String,
              value: reiss.AllAnswers.Q19.split(",")[1],
            },
            // "Q20"
            {
              type: String,
              value: reiss.AllAnswers.Q20.split(",")[1],
            },
            // "Q21"
            {
              type: String,
              value: reiss.AllAnswers.Q21.split(",")[1],
            },
            // "Q22"
            {
              type: String,
              value: reiss.AllAnswers.Q22.split(",")[1],
            },
            // "Q23"
            {
              type: String,
              value: reiss.AllAnswers.Q23.split(",")[1],
            },
            // "Q24"
            {
              type: String,
              value: reiss.AllAnswers.Q24.split(",")[1],
            },
            // "Q25"
            {
              type: String,
              value: reiss.AllAnswers.Q25.split(",")[1],
            },
            // "Q26"
            {
              type: String,
              value: reiss.AllAnswers.Q26.split(",")[1],
            },
            // "Q27"
            {
              type: String,
              value: reiss.AllAnswers.Q27.split(",")[1],
            },
            // "Q28"
            {
              type: String,
              value: reiss.AllAnswers.Q28.split(",")[1],
            },
            // "Q29"
            {
              type: String,
              value: reiss.AllAnswers.Q29.split(",")[1],
            },
            // "Q30"
            {
              type: String,
              value: reiss.AllAnswers.Q30.split(",")[1],
            },
          ];

          data.push(DATA_ROW);
        });

        await writeXlsxFile([data, questionsInfo], {
          sheets: ["Results", "Questions"],
          fileName: "file.csv",
        });
      }
    } catch (e) {
      console.log("Something went wrong", e);
    }
  }

  const handleMenuItemClick = (path) => {
    console.log(path);
    setUrl(path);
    handleCloseNavMenu();
    navigate(url);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function handleClickLogIn() {
    navigate("/SignIn");
  }

  function handleClickLogOut() {
    localStorage.clear();
    navigate("/SignIn");
  }

  function handleResetChat() {
    localStorage.clear();
    navigate("/");
    navigate(0);
  }

  function LogInOut() {
    const logout = (
      <>
        <Chip label="Reset Chat" onClick={handleResetChat} color="secondary" />
        <Chip label="Log Out" onClick={handleClickLogOut} color="warning" />
      </>
    );

    const login = (
      <>
        <Chip label="Reset Chat" onClick={handleResetChat} color="secondary" />
        <Chip label="Log In" onClick={handleClickLogIn} color="info" />
      </>
    );

    switch (logged) {
      case "yes":
        return logout;
        break;

      case "no":
        return login;
        break;

      default:
        return login;
        break;
    }
  }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  function SelectYourAvatar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleAvatar = (avatarType) => {
      localStorage.setItem("avatarType", avatarType);
      setAnchorEl(null);
      navigate("/");
      navigate(0);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Avatar
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleAvatar("Latin");
            }}
            disableRipple
          >
            <Avatar
              alt="Remy Sharp"
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Anna_Avatar.jpg"
              sx={{ width: 50, height: 50, m: "auto", display: "block" }}
            />
            Anna
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAvatar("Black");
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Sally_Avatar.jpg"
              sx={{ width: 50, height: 50, m: "auto", display: "block" }}
            />
            Sally
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAvatar("Caucasian");
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Aiden_Avatar.jpg"
              sx={{ width: 50, height: 50, m: "auto", display: "block" }}
            />
            John
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              handleAvatar("Caucasian");
            }}
            disableRipple
          >
            Caucasian
          </MenuItem> */}
        </StyledMenu>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* <HideOnScroll {...props}> */}
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              height="50"
              width="50"
              src="https://reactivity.s3.us-west-1.amazonaws.com/chatbot/Atom_1.0_icon.png"
            ></img>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              REACTIVITY
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => {
                      navigate(page.path);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  key="CSV"
                  onClick={() => {
                    downloadCSV();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">/ CSV</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => {
                    navigate(page.path);
                    handleCloseNavMenu();
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.name}
                </Button>
              ))}
              <Button
                key="CSV"
                onClick={() => {
                  downloadCSV();
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                / CSV
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Stack direction="row" spacing={1}>
                <SelectYourAvatar />
                <LogInOut />
              </Stack>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* </HideOnScroll> */}
    </React.Fragment>
  );
}
